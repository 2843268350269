class RuleTypeModel {
    constructor(
        id = 0,
        name = "",
        entityTypeKey = "",
        supportedApplicationId = ""
    ) {
        this.id = id;
        this.name = name;
        this.entityTypeKey = entityTypeKey;
        this.supportedApplicationId = supportedApplicationId;
    }
}

export { RuleTypeModel };
