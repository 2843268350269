import React from "react";
import { Navigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../common/loader/Loader.component";

const GeneralSettings = () => {
  const { tenantId } = useParams();
  return (
    <>
      <LoadingSpinner />
      <Navigate to={"/GeneralSettings/Tenants" + "/" + tenantId} />
    </>
  );
};

export default GeneralSettings;
