import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NotificationSpaceModel } from "../../models/NotificationSpace";
import SideModal from "../../common/modal/SideModal.component";
import NotificationSpace from "../../components/notification-space/NotificationSpace.component";
import CreateNotificationSpace from "../../components/create-notification-space/CreateNotificationSpace.component";
import IndexClient from "../../api-services/index/index-client";
import RenderOnPrivilege from "../../validations/RenderOnPrivilege";

const Index = () => {
  const { tenantId } = useParams();

  const [data, setData] = useState(new NotificationSpaceModel());
  const [allNotificationSpaces, setAllNotificationSpaces] = useState([]);
  const [show, setShow] = useState(false);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const client = new IndexClient();

  useEffect(() => {
    if (tenantId) {
      getNotificationSpaces();
    }
  }, [tenantId]);

  const getNotificationSpaces = async () => {
    let response = await client.GetAllNotificationSpaces(tenantId);
    setAllNotificationSpaces(response);
  };

  const onCreateClick = () => {
    setData(new NotificationSpaceModel());
    showModal();
  };

  const onEditClick = (data) => {
    setData(data);
    showModal();
  };

  const handleSubmitAction = (object) => {
    hideModal();
    let index = allNotificationSpaces.findIndex((x) => x.id === object.id);
    if (index !== -1) {
      let spacesCopy = [...allNotificationSpaces];
      spacesCopy[index] = object;
      setAllNotificationSpaces(spacesCopy);
    }
    else allNotificationSpaces.push(object);
  };

  return (
    <div className="container clearfix">
      <RenderOnPrivilege>
        <div>
          <button
            className="btn btn-primary"
            onClick={onCreateClick}
            type="button"
          >
            Create new notification space
          </button>
        </div>
      </RenderOnPrivilege>
      <div className="row">
        {allNotificationSpaces.map((item) => {
          return (
            <NotificationSpace
              key={item.applicationKey}
              id={item.id}
              name={item.name}
              applicationKey={item.applicationKey}
            >
              <RenderOnPrivilege>
                <button
                  onClick={() => onEditClick(item)}
                  type="button"
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon
                    title={item.applicationKey}
                    icon={solid("pen-to-square")}
                    size="lg"
                  />
                </button>
              </RenderOnPrivilege>
            </NotificationSpace>
          );
        })}
      </div>
      <SideModal
        show={show}
        handleClose={hideModal}
        title={data.id == 0 ? "Creation of notification space" : "Modification of notification space"}
      >
        <CreateNotificationSpace
          data={data}
          handleSubmitAction={handleSubmitAction}
        />
      </SideModal>
    </div>
  );
};

export default Index;
