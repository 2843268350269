import { ModalContainer } from "./SideModal.style.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const SideModal = ({ handleClose, show, children, title }) => {
  if (show) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  return (
    <ModalContainer>
      <div className={`Modal ${show ? "show" : ""}`}>
        <div className="modal-header">
          <button
            type="button"
            className="close-dialog-btn"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={solid("times")} />
          </button>
          <h4>{title}</h4>
        </div>
        <div className="modal-body-scroll">{children}</div>
      </div>
      <div className={`overlay ${show ? "show" : ""}`} onClick={handleClose} />
    </ModalContainer>
  );
};

export default SideModal;