import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../common/table/Table.component";
import SideModal from "../../common/modal/SideModal.component";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import useApplications from "../../hooks/useApplications";
import { ApplicationModel } from "../../models/Application";
import CreateApplication from "../../components/create-application/CreateApplication.component";
import ApplicationsClient from "../../api-services/applications/applications-client";

const Applications = () => {
  const { applications, setApplications } = useApplications([]);

  const [rowData, setRowData] = useState(new ApplicationModel());
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);

  const columns = [
    {
      Header: "Application id",
      accessor: "id",
    },
    {
      Header: "Application name",
      accessor: "name",
      disableFilters: false,
    },
    {
      accessor: "edit",
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
      disableFilters: true,
    },
    {
      accessor: "delete",
      Cell: ({ row }) => {
        return (
          <ButtonWithConfirmation
            className="btn btn-danger"
            onClick={() => onDeleteClick(row.original.id)}
            handleYesText={"Delete"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithConfirmation>
        );
      },
      disableFilters: true,
    },
  ];

  const client = new ApplicationsClient();

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const handleCallback = (childData) => {
    hideModal();
    if (isCreate) setApplications([...applications, childData]);
    else {
      let allApplicationsCopy = [...applications];
      let index = allApplicationsCopy.findIndex((x) => x.id === childData.id);
      allApplicationsCopy[index] = childData;
      setApplications(allApplicationsCopy);
    }
  };

  const onCreateClick = () => {
    setRowData(new ApplicationModel());
    setIsCreate(true);
    showModal();
  };

  const onEditClick = (row) => {
    setRowData(row);
    setIsCreate(false);
    showModal();
  };

  const onDeleteClick = async (appId) => {
    await client.removeSupportedApplication(appId);
    let allApplicationsCopy = applications.filter((x) => x.id !== appId);
    setApplications(allApplicationsCopy);
  };

  return (
    <div className="container clearfix">
      <button
        className="btn btn-primary"
        onClick={onCreateClick}
        type="button"
      >
        Create new application
      </button>
      <Table columns={columns} data={applications} hiddenColumn={["id"]} rememberFilter={false} />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Creation of application" : "Modification of application"}
      >
        <CreateApplication
          row={rowData}
          isCreate={isCreate}
          handleCallback={handleCallback}
        />
      </SideModal>
    </div>
  );
};

export default Applications;
