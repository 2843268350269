import api from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class RulesTypesClient {

    async getAllEntityTypes() {
        var response = await api.get("/getAllEntityTypes");
        return response.data;
    }

    async createEntityType(data) {
        try {
            var response = await api.post("/createEntityType", data);
            if (response.status !== 200)
                Error(response.message);
            else {
                Success("Successfully!");
                return response.data;
            }
        }
        catch (err) {
            Error(err.message);
        }
    }

    async updateEntityType(data) {
        try {
            var response = await api.post("/updateEntityType", data);
            if (response.status !== 200)
                Error(response.message);
            else {
                Success("Successfully!");
                return response.data;
            }
        }
        catch (err) {
            Error(err.message);
        }
    }

    async removeEntityType(entityTypeId) {
        try {
            var response = await api.delete("/removeEntityType?entityTypeId=" + entityTypeId);
            if (response.status !== 200)
                Error(response.message);
            else
                Success("Successfully!");
        }
        catch (err) {
            Error(err.message);
            throw err?.message
        }
    }

}

export default RulesTypesClient;