import api from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class ApplicationsClient {

    async getAllSupportedApplications() {
        var response = await api.get("/getAllSupportedApplications");
        return response.data;
    }

    async createSupportedApplication(data) {
        try {
            var response = await api.post("/createSupportedApplication", data);
            if (response.status !== 200)
                Error(response.message);
            else
                Success("Successfully!");
            return response.data;
        }
        catch (err) {
            Error(err.message);
        }
    }

    async updateSupportedApplication(data) {
        try {
            var response = await api.post("/updateSupportedApplication", data);
            if (response.status !== 200)
                Error(response.message);
            else
                Success("Successfully!");
            return response.data;
        }
        catch (err) {
            Error(err.message);
        }
    }

    async removeSupportedApplication(supportedApplicationId) {
        try {
            var response = await api.delete("/removeSupportedApplication?supportedApplicationId=" + supportedApplicationId);
            if (response.status !== 200)
                Error(response.message);
            else {
                Success("Successfully!");
                return response;
            }
        }
        catch (err) {
            Error(err?.message);
            throw err?.message
        }
    }
}

export default ApplicationsClient;     