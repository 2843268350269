import API from '../../api'
import { Success, Error } from '../../common/toastify/toastify'

class NotificationTargetInfoClient {
    async getAllNotificationTargetInfos(tenantId) {
        var response = await API.get("/getAllNotificationTargetInfos", {
            headers: { tenantId: tenantId },
        });
        return response.data;
    }

    async createNotificationTargetInfo(tenantId, data) {
        const headers = {
            'Content-Type': 'application/json',
            'tenantId': tenantId,
        };
        try {
            var response = await API.post('/createNotificationTargetInfo', data, {
                headers: headers
            });
            if (response.status !== 200)
                Error(response.message);
            else {
                Success("Successfully!");
                return response.data;
            }
        }
        catch (err) {
            Error(err.message);
        }

    }

    async updateNotificationTargetInfo(tenantId, data) {
        const headers = {
            'Content-Type': 'application/json',
            'tenantId': tenantId,
        };
        try {
            var response = await API.post('/updateNotificationTargetInfo', data, {
                headers: headers
            });
            if (response.status !== 200)
                Error(response.message);
            else {
                Success("Successfully!");
                return response.data;
            }
        }
        catch (err) {
            Error(err.message);
        }

    }

    async removeNotificationTargetInfo(tenantId, notificationTargetInfoId) {
        var response = await API.delete(
            "/removeNotificationTargetInfo?notificationTargetInfoId=" +
            notificationTargetInfoId, {
            headers: { tenantId: tenantId },
        }
        );
        if (response.status !== 200)
            Error(response.message);
        else
            Success("Successfully!");
    }

    async getNotificationSpaceUserGroupIdentifiersByNotificationSpaceId(tenantId, notificationSpaceId) {
        const headers = {
            'Content-Type': 'application/json',
            'tenantId': tenantId,
        };
        try {
            var response = await API.get("/getNotificationSpaceUserGroupIdentifiersByNotificationSpaceId?notificationSpaceId=" + notificationSpaceId, {
                headers: headers
            });
            return response.data;
        }
        catch (err) {
            Error(err.message);
        }
    }
}


export default NotificationTargetInfoClient;