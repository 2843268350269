import {  toast } from "react-toastify";

const options = {
    hideProgressBar: true,
    position: "top-center",
    autoClose: 3000,
    zIndex: 100000,
}

export const Success = (message) => {
    toast.success(message, options)
}

export const Error = (message) => {
    if(!message){
        message = "Doslo je do greske"
    }
    toast.error(message, options);
}

export const Warning = (message) => {
    toast.warning(message, options);
}

export const Info = (message) => {
    toast.info(message, options);
}

