import styled from "styled-components";

export const NotificationContainer = styled.div`
  display: flex;

  .events-holder {
    width: 300px;
  }

  .nav-n-table {
    width: 100%;
  }

  .event-list div {
    cursor: pointer;
    padding-left: 5px;
  }

  .nav-buttons {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 50px;

    label {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 18px;
      cursor: pointer;
    }
  }
`;

export const RemoveTableBtn = styled.button`
  border: none;
  color: red;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  float: right;
`;
