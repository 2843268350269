import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { GeneralSettingsNav, NSpaceNav, SettingsNav } from "./SlideBarData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NavBarButton } from "./Navigation.style";
import { SideNavBar } from "./Navigation.style";
import { NavLink } from "react-router-dom";
import useNav from "../../hooks/useNav";
import { LogoButtonDiv } from "./Navigation.style";
import { SubLogoDiv } from "./Navigation.style";
import IndexClient from "../../api-services/index/index-client";
import "./Navigation.style.css";
import UserService from "../../utils/authentification/keycloak";
import useUserPrivileges from "../../hooks/useUserPrivileges";

const Navbar = () => {
  const { sidebar, setSidebar } = useNav(true);

  const { tenantId } = useParams();
  const { notificationSpaceId } = useParams();

  const { userPrivileges, setUserPrivileges } = useUserPrivileges();

  const [allTenants, setAllTenants] = useState([]);

  const client = new IndexClient();

  useEffect(() => {
    getTenants();
  }, []);

  const getTenants = async () => {
    let id = UserService.getInternalIdentityId();
    let response = await client.GetPrivilege(id);
    setAllTenants(response);
  };

  let location = useLocation();
  let MenuItems = [];
  if (notificationSpaceId && location.pathname.includes(notificationSpaceId)) {
    MenuItems = NSpaceNav;
  } else if (location.pathname.includes("/Settings")) {
    MenuItems = SettingsNav;
  } else if (location.pathname.includes("/GeneralSettings")) {
    MenuItems = GeneralSettingsNav;
  } else {
    MenuItems = allTenants;
  }

  return (
    <>
      {UserService.isLoggedIn() && (
        <div className="header-navigation">
          <button
            style={{ float: "right" }}
            className="btn btn-primary m-2"
            onClick={() => {
              sessionStorage.clear();
              localStorage.clear();
              UserService.doLogout({
                redirectUri: window.location.origin + "/",
              });
            }}
          >
            Logout ({UserService.getUsername()})
          </button>
        </div>
      )}
      <SideNavBar
        className={sidebar ? "side-navigation" : "side-navigation collapsed"}
      >
        <div className="nav-link-container">
          <LogoButtonDiv>
            <div>
              {sidebar ? (
                <span className="default-link-text">
                  <Link className="fade-in-anim" to={"/" + tenantId}>
                    <h5 className="nav-logo-text">Notification Engine</h5>
                  </Link>
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="text-right">
              <NavBarButton onClick={() => setSidebar(!sidebar)}>
                <FontAwesomeIcon
                  icon={
                    sidebar
                      ? solid("angle-double-left")
                      : solid("angle-double-right")
                  }
                />
              </NavBarButton>
            </div>
          </LogoButtonDiv>
          <SubLogoDiv>
            {sidebar ? (
              ""
            ) : (
              <Link className="fade-in-anim" to={"/" + tenantId}>
                <b>NE</b>
              </Link>
            )}
          </SubLogoDiv>
          {MenuItems.map((item, index) => {
            if (MenuItems == allTenants) {
              item.path = "/" + item.tenantId;
              item.title = item.tenantName;
              item.icon = <FontAwesomeIcon icon={solid("industry")} />;
              item.cName = "tenant-nav";
              item.id = item.tenantId;
            } else if (MenuItems == SettingsNav) {
              item.path = "Settings/" + item.pathForNav + "/" + tenantId;
            } else if (MenuItems == GeneralSettingsNav) {
              item.path = "GeneralSettings/" + item.pathForNav + "/" + tenantId;
            } else {
              item.path =
                item.title + "/" + tenantId + "&" + notificationSpaceId;
            }
            return (
              <div key={index} className={item.cName}>
                {item.cName == "tenant-nav" ? (
                  <>
                    <Link
                      className={
                        location.pathname.includes(item.tenantId)
                          ? "active-tenant"
                          : ""
                      }
                      to={item.path}
                      onClick={() => {
                        let hasPrivilege = item.privilege == 0;
                        setUserPrivileges(hasPrivilege);
                        localStorage.setItem("privilege", hasPrivilege);
                      }}
                    >
                      {sidebar ? (
                        <>
                          <i>{item.icon}</i>
                          <span>{item.title}</span>
                        </>
                      ) : (
                        <>
                          <i title={item.title}>{item.icon}</i>
                          <span></span>
                        </>
                      )}
                    </Link>
                  </>
                ) : (
                  (item.title == "Notifications" || userPrivileges) && (
                    <NavLink to={item.path}>
                      {sidebar ? (
                        <>
                          <i>{item.icon}</i>
                          <span>{item.title}</span>
                        </>
                      ) : (
                        <>
                          <i title={item.title}>{item.icon}</i>
                          <span></span>
                        </>
                      )}
                    </NavLink>
                  )
                )}
              </div>
            );
          })}
        </div>
        <div className="nav-link-container">
          {tenantId && userPrivileges && (
            <NavLink
              className={
                location.pathname.includes("/Settings") ? "active" : ""
              }
              to={"/Settings" + "/" + tenantId}
            >
              {sidebar ? (
                <>
                  <i>
                    <FontAwesomeIcon icon={solid("cog")} />
                  </i>
                  <span>Settings</span>
                </>
              ) : (
                <>
                  <i title="Settings">
                    <FontAwesomeIcon icon={solid("cog")} />
                  </i>
                  <span></span>
                </>
              )}
            </NavLink>
          )}
          {userPrivileges && (
            <NavLink
              className={
                location.pathname.includes("GeneralSettings") ? "active" : ""
              }
              to={"/GeneralSettings" + "/" + tenantId}
            >
              {sidebar ? (
                <>
                  <i>
                    <FontAwesomeIcon icon={solid("cog")} />
                  </i>
                  <span>General settings</span>
                </>
              ) : (
                <>
                  <i title="GeneralSettings">
                    <FontAwesomeIcon icon={solid("cog")} />
                  </i>
                  <span></span>
                </>
              )}
            </NavLink>
          )}
        </div>
      </SideNavBar>
    </>
  );
};

export default Navbar;
