import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./utils/localization/localization";
import API from "./api";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavProvider } from "./contexts/NavProvider";
import { TenantProvider } from "./contexts/TenantProvider";
import { ApplicationProvider } from "./contexts/ApplicationProvider";
import { TokenProvider } from "./contexts/TokenProvider";
import { RuleTypeProvider } from "./contexts/RuleTypeProvider";
import { ToastContainer } from "react-toastify";
import { NotificationSpaceProvider } from "./contexts/NotificationSpaceProvider";
import UserService from "./utils/authentification/keycloak";
import { UserPrivilegesProvider } from "./contexts/UserPrivilegesProvider";

API.interceptors.request.use(
  (config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`;
        return Promise.resolve(config);
      };
      return UserService.updateToken(cb);
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));

const renderApp = () =>
  root.render(
    <BrowserRouter>
      {/* <TenantProvider> */}
      <NotificationSpaceProvider>
        <NavProvider>
          <UserPrivilegesProvider>
            <ToastContainer limit={1} />
            <Routes>
              <Route path="/*" element={<App />}></Route>
            </Routes>
          </UserPrivilegesProvider>
        </NavProvider>
      </NotificationSpaceProvider>
      {/* </TenantProvider> */}
    </BrowserRouter>
  );

UserService.initKeycloak(renderApp);
