class NotificationSpaceModel {
  constructor(
    id = 0,
    name = "",
    supportedApplicationId = "",
    applicationKey = ""
  ) {
    this.id = id;
    this.name = name;
    this.supportedApplicationId = supportedApplicationId;
    this.applicationKey = applicationKey;
  }
}

export { NotificationSpaceModel };
