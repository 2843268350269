import api from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class RulesClient {
  async getAllRules(tenantId, notificationSpaceId) {
    var response = await api.get(
      "/getAllRulesWithEntityTypes?tenantId=" +
      tenantId +
      "&notificationSpaceId=" +
      notificationSpaceId
    );
    return response.data;
  }

  async getAllEntityTypes(tenantId, notificationSpaceId) {
    var response = await api.get(
      "/getAllEntityTypes?tenantId=" +
      tenantId +
      "&notificationSpaceId=" +
      notificationSpaceId
    );

    var data = response.data.map((item) => {
      return { value: item.id, label: item.name };
    });

    return data;
  }

  async createRule(tenantId, data) {
    const headers = {
      "Content-Type": "application/json",
      tenantId: tenantId,
    };
    try {
      var response = await api.post("/createRule", data, {
        headers: headers,
      });
      if (response.status !== 200) Error(response.message);
      else Success("Successfully!");
      return response.data;
    } catch (err) {
      Error(err.message);
    }
  }

  async updateRule(tenantId, data) {
    const headers = {
      "Content-Type": "application/json",
      tenantId: tenantId,
    };
    try {
      var response = await api.post("/updateRule", data, {
        headers: headers,
      });
      if (response.status !== 200) Error(response.message);
      else Success("Successfully!");
      return response.data;
    } catch (err) {
      Error(err.message);
    }
  }

  async removeRule(tenantId, ruleId) {
    var response = await api.delete(
      "/removeRule?ruleId=" +
      ruleId, {
      headers: { tenantId: tenantId },
    }
    );
    if (response.status !== 200)
      Error(response.message);
    else
      Success("Successfully!");
  }
}

export default RulesClient;
