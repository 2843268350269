import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import IndexClient from "../../api-services/index/index-client";
import { NotificationSpaceModel } from "../../models/NotificationSpace";

const CreateNotificationSpace = ({ data, handleSubmitAction }) => {
  const { tenantId } = useParams();

  const [notificationSpace, setNotificationSpace] = useState(new NotificationSpaceModel());
  const [applications, setApplications] = useState([]);

  const client = new IndexClient();

  useEffect(() => {
    getApplications();
  }, []);

  useEffect(() => {
    setNotificationSpace(data);
  }, [data]);

  const getApplications = async () => {
    let response = await client.GetAllSupportedApplications();
    setApplications(response);
  };

  const saveChanges = async () => {
    const data = {
      ...notificationSpace,
      fK_Tenant: tenantId,
    };
    if (notificationSpace.id != 0) {
      var responseData = await client.UpdateNotificationSpace(tenantId, data);
    } else {
      var responseData = await client.CreateNotificationSpace(tenantId, data);
    }
    handleSubmitAction(responseData);
  };

  return (
    <form>
      <div>
        <div className="form-group row g-2">
          <div className="col-md">
            <label>Notification space name</label>
            <input
              className="form-control"
              value={notificationSpace.name}
              onChange={(event) => setNotificationSpace({ ...notificationSpace, name: event.target.value })}
            />
          </div>
          <div className="col-md">
            <label>Application</label>
            <Select
              options={applications}
              onChange={(event) => setNotificationSpace({ ...notificationSpace, supportedApplicationId: event.value })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={applications.filter((x) => x.value === notificationSpace.supportedApplicationId)}
            />
          </div>
        </div>
        <div className="form-group row g-2">
          {notificationSpace.applicationKey && (
            <div className="col-md">
              <label>Notification space key</label>
              <div>{notificationSpace.applicationKey}</div>
            </div>
          )}
        </div>
      </div>
      <div className="text-right">
        <button className="btn btn-primary" type="button" onClick={saveChanges}>
          Save
        </button>
      </div>
    </form>
  );
};

export default CreateNotificationSpace;
