import React from "react";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "../custom-select-list/CustomSelectList.component";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, filteredRows, setFilter },
}) {
  
  return (
    <input
      className="form-control"
      type="text"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search`}
    />
  );
}

/**
 *@param rows - niz svih podataka
 *@param id - kolona po kojoj se vrsi pretraga
 *@param filterValue - podatak koji se pretrazuje
 *@return niz filtriranih podataka
 **/
function textFilterFunction(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.original[id]] });
}

// Let the table remove the filter if the string is empty
textFilterFunction.autoRemove = (val) => !val;

const SelectColumnFilter = ({
  column: { filter, setFilter, filterValue, },
  array: array,
}) => {

  // Render a multi-select box
  return (
    <div style={{ minWidth: "150px" }}>
      <Select
        options={filter.selectOptions}
        onChange={(e) => {
          setFilter(e.value);
        }}
        value={filter.selectOptions.filter(
          (x) => x.value === filterValue
        )}
      />
    </div>
  );
};

function dateBetweenFilterFn(rows, id, filterValue) {
  const value = filterValue ? new Date(filterValue) : undefined;
  if (value) {
    return rows.filter((r) => {
      const cellDate = new Date(r.values[id]);
      var date = cellDate.getDate();
      var month = cellDate.getMonth();
      var year = cellDate.getFullYear();

      return (
        date == value.getDate() &&
        month == value.getMonth() &&
        year == value.getFullYear()
      );
    });
  } else {
    return rows;
  }
}

function dateFromFilterFn(rows, id, filterValue) {
  const value = filterValue ? new Date(filterValue) : undefined;
  if (value) {
    return rows.filter((r) => {
      const cellDate = new Date(r.values[id]);
      var date = cellDate.getDate();
      var month = cellDate.getMonth();
      var year = cellDate.getFullYear();

      return (
        year > value.getFullYear() ||
        (month > value.getMonth() && year == value.getFullYear()) ||
        (date >= value.getDate() &&
          month == value.getMonth() &&
          year == value.getFullYear())
      );
    });
  } else {
    return rows;
  }
}

function dateToFilterFn(rows, id, filterValue) {
  const value = filterValue ? new Date(filterValue) : undefined;
  if (value) {
    return rows.filter((r) => {
      const cellDate = new Date(r.values[id]);
      var date = cellDate.getDate();
      var month = cellDate.getMonth();
      var year = cellDate.getFullYear();

      return (
        year < value.getFullYear() ||
        (month < value.getMonth() && year == value.getFullYear()) ||
        (date <= value.getDate() &&
          month == value.getMonth() &&
          year == value.getFullYear())
      );
    });
  } else {
    return rows;
  }
}

function DateRangeColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);

    preFilteredRows.forEach((row) => {
      const rowDate = new Date(row.values[id]);

      min = rowDate <= min ? rowDate : min;
      max = rowDate >= max ? rowDate : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div>
      <DatePicker
        className="form-control"
        selected={filterValue}
        onChange={(date) => setFilter(date)}
        placeholderText="Izaberi datum"
        showMonthDropdown
        useShortMonthInDropdown
        isClearable
        dateFormat={"dd.MM.yyyy"}
      />
    </div>
  );
}

export {
  DefaultColumnFilter,
  textFilterFunction,
  SelectColumnFilter,
  dateBetweenFilterFn,
  dateFromFilterFn,
  dateToFilterFn,
  DateRangeColumnFilter,
};
