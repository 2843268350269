import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RuleModel } from "../../models/Rule";
import Select from "react-select";
import Switch from "../../common/switch/Switch.component";
import useTokens from "../../hooks/useTokens";
import TokensTablePreview from "../tokens/TokensTablePreview.component";
import RulesClient from "../../api-services/rules/rules-client";

const CreateRule = ({ row, entityTypeName, isCreate, handleCallback }) => {
  const { tenantId } = useParams();
  const { notificationSpaceId } = useParams();
  const { tokens } = useTokens([]);

  const [rule, setRule] = useState(new RuleModel());
  const [isCreateRule, setIsCreateRule] = useState(true);
  const [entityTypes, setEntityTypes] = useState([]);
  const [entityName, setEntityName] = useState("");

  const client = new RulesClient();

  useEffect(() => {
    getAllEntityTypes();
  }, []);

  useEffect(() => {
    setRule(row);
    setEntityName(entityTypeName);
    setIsCreateRule(isCreate);
  }, [row, entityTypeName, isCreate]);

  const getAllEntityTypes = async () => {
    let response = await client.getAllEntityTypes(tenantId, notificationSpaceId);
    setEntityTypes(response);
  };

  const save = async () => {
    const data = {
      ...rule,
      notificationSpaceId: notificationSpaceId,
    };
    if (isCreateRule) {
      var response = await client.createRule(tenantId, data);
    } else {
      var response = await client.updateRule(tenantId, data);
    }
    handleCallback(response, entityName);
  };

  return (
    <form>
      <div className="form-group">
        <Switch
          label="Current"
          value={rule.ruleForScheduleNotification}
          onChange={(value) => setRule({ ...rule, ruleForScheduleNotification: value })}
        />
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Name</label>
          <input
            className="form-control"
            value={rule.name}
            onChange={(event) => setRule({ ...rule, name: event.target.value })}
          />
        </div>
        <div className="col-md">
          <label>Rule type</label>
          <Select
            options={entityTypes}
            onChange={(event) => {
              setEntityName(event.label);
              setRule({ ...rule, entityTypeId: event.value });
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={entityTypes.filter((x) => x.value === rule.entityTypeId)}
          />
        </div>
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Rule template</label>
          <textarea
            className="form-control"
            value={rule.ruleTemplate}
            onChange={(event) => setRule({ ...rule, ruleTemplate: event.target.value })}
          ></textarea>
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          type="button"
          onClick={save}
        >
          {isCreateRule ? "Create" : "Save changes"}
        </button>
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          {rule.entityTypeId !== "" && (
            <TokensTablePreview
              dataTokens={tokens.filter(
                (x) => x.entityTypeId === rule.entityTypeId
              )}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default CreateRule;
