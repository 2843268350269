import API from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class ChannelsClient {

  async getAllChannels(tenantId) {
    var response = await API.get("/getAllChannels", {
      headers: { tenantId: tenantId },
    });
    return response.data;
  }

  async createChannel(tenantId, data) {
    const headers = {
      'Content-Type': 'application/json',
      'tenantId': tenantId,
    };
    try {
      var response = await API.post('/createChannel', data, {
        headers: headers
      });
      if (response.status !== 200)
        Error(response.message);
      else {
        Success("Successfully!");
        return response.data;
      }
    }
    catch (err) {
      Error(err.message);
    }

  }

  async updateChannel(tenantId, data) {
    const headers = {
      'Content-Type': 'application/json',
      'tenantId': tenantId,
    };
    try {
      var response = await API.post('/updateChannel', data, {
        headers: headers
      })
      if (response.status !== 200)
        Error(response.message);
      else {
        Success("Successfully!");
        return response.data;
      }
    }
    catch (err) {
      Error(err.message);
    }

  }

  async removeChannel(tenantId, channelId) {
    try {
      var response = await API.delete(
        "/removeChannel?channelId=" +
        channelId,
        { headers: { tenantId: tenantId } }
      );
      if (response.status !== 200)
        Error(response.message);
      else
        Success("Successfully!");
    }
    catch (err) {
      Error(err?.message);
      throw err?.message
    }
  }
}

export default ChannelsClient;
