import Table from "../../common/table/Table.component";

const TokensTablePreview = ({ dataTokens }) => {
    const columns = [
        {
            Header: "Token id",
            accessor: "id",
        },
        {
            Header: "Token name",
            accessor: "tokenName"
        },
        {
            Header: "Description",
            accessor: "description"
        }
    ];

    return (
        <div className="container clearfix">
            <Table
                columns={columns}
                data={dataTokens}
                hiddenColumn={["id"]}
                rememberFilter={false}
            />
        </div>
    )

};

export default TokensTablePreview;