class RuleModel {
  constructor(
    id = 0,
    name = "",
    entityTypeId = "",
    ruleTemplate = "",
    ruleForScheduleNotification = false
  ) {
    this.id = id;
    this.name = name;
    this.entityTypeId = entityTypeId;
    this.ruleTemplate = ruleTemplate;
    this.ruleForScheduleNotification = ruleForScheduleNotification;
  }
}

export { RuleModel };
