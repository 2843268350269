import styled from "styled-components";

export const MainContainer = styled.main`
  margin-left: 250px;
  margin-top: 75px;
  transition: all 0.2s ease-in-out;

  &.collapsed {
    margin-left: 0;
  }
`;
