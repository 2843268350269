import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { EventModel } from "../../models/Event";
import useTokens from "../../hooks/useTokens";
import Select from "react-select";
import Switch from "../../common/switch/Switch.component";
import TokensTablePreview from "../tokens/TokensTablePreview.component";
import EventsClient from "../../api-services/events/events-client";
import RulesClient from "../../api-services/rules/rules-client";

const CreateEvent = ({ row, handleCallback, isCreate }) => {
  const { tenantId } = useParams();
  const { notificationSpaceId } = useParams();
  const { tokens, setTokens } = useTokens([]);

  const [event, setEvent] = useState(new EventModel());
  const [rules, setRules] = useState([]);
  const [rulesList, setRulesList] = useState([]);
  const [isCreateForm, setIsCreateForm] = useState(true);

  const client = new EventsClient();
  const ruleClient = new RulesClient();

  useEffect(() => {
    if (tenantId && notificationSpaceId) {
      getAllRules();
    }
  }, []);

  useEffect(() => {
    if (rules.length > 0 && event.ruleId > 0) {
      let entityId = rules.find((x) => x.id === event.ruleId).entityTypeId;
      setEvent({ ...event, entityTypeId: entityId });
    }
  }, [rules, event.ruleId, event.entityTypeId]);

  useEffect(() => {
    setIsCreateForm(isCreate);
    setEvent(row);
  }, [row, isCreate]);

  const getAllRules = async () => {
    let response = await ruleClient.getAllRules(tenantId, notificationSpaceId);
    setRules(response);
    var data = response.map((item) => {
      return { value: item.id, label: item.name };
    });
    setRulesList(data);
  };

  const save = async () => {
    const data = {
      ...event,
      notificationSpaceId: notificationSpaceId,
    };
    if (isCreateForm) {
      var response = await client.createEventWithRule(tenantId, data);
    } else {
      var response = await client.updateEventWithRule(tenantId, data);
    }
    handleCallback(response);
  };

  return notificationSpaceId ? (
    <form>
      <div className="form-group">
        <Switch
          label="Active"
          value={event.status}
          onChange={(value) => setEvent({ ...event, status: value })}
        />
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          <div>
            <label>Rule</label>
          </div>
          <Select
            options={rulesList}
            onChange={(e) => {
              setEvent({
                ...event,
                ruleId: e.value,
                ruleName: e.label
              })
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={rulesList.filter((x) => x.value === event.ruleId)}
          />
        </div>
        <div className="col-md">
          <div>
            <label>Name</label>
          </div>
          <input
            className="form-control"
            value={event.name}
            onChange={(e) => setEvent({ ...event, name: e.target.value })}
          />
        </div>
      </div>

      <div className=" form-group row g-2">
        <div className="col-xs">
          <label>Subject</label>
          <input
            className="form-control"
            value={event.subjectTemplate}
            onChange={(e) => setEvent({ ...event, subjectTemplate: e.target.value })}
          />
        </div>
      </div>
      <div className="form-group row g-2">
        <div className="col-xs">
          <label>Body template</label>
          <textarea
            className="form-control"
            value={event.bodyTemplate}
            onChange={(e) => setEvent({ ...event, bodyTemplate: e.target.value })}
          ></textarea>
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          type="button"
          onClick={save}
        >
          {isCreateForm ? "Create" : "Save changes"}
        </button>
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          {event.entityTypeId !== "" && (
            <TokensTablePreview
              dataTokens={tokens.filter(
                (x) => x.entityTypeId === event.entityTypeId
              )}
            />
          )}
        </div>
      </div>
    </form>
  ) : (
    <Navigate to="/" />
  );
};

export default CreateEvent;
