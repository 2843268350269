import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../common/table/Table.component";
import { SelectColumnFilter } from "../../common/table/filterHelpFunction.component";
import SideModal from "../../common/modal/SideModal.component";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import useRulesTypes from "../../hooks/useRulesTypes";
import useTokens from "../../hooks/useTokens";
import { TokenModel } from "../../models/Token";
import CreateToken from "../../components/create-token/createToken.component";
import TokensClient from "../../api-services/tokens/tokens-client";

const Tokens = () => {
  const { rulesTypes, setRulesTypes } = useRulesTypes([]);
  const { tokens, setTokens } = useTokens([]);

  const [rowData, setRowData] = useState(new TokenModel());
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);

  const filter = {
    filterValue: "equals",
    selectOptions: rulesTypes.map((x) => {
      return (
        {
          value: x.id, label: x.name
        }
      )
    })
  };

  filter.selectOptions.unshift({ value: undefined, label: "All" });

  const columns = [
    {
      Header: "Token id",
      accessor: "id",
    },
    {
      Header: "Token name",
      accessor: "tokenName",
      disableFilters: false,
    },
    {
      Header: "Description",
      accessor: "description",
      disableFilters: false,
    },
    {
      Header: "Rule type",
      accessor: "entityTypeId",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return rulesTypes.find((x) => x.id === row.original.entityTypeId)?.name;
      },
    },
    {
      accessor: "edit",
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
      disableFilters: true,
    },
    {
      accessor: "delete",
      Cell: ({ row }) => {
        return (
          <ButtonWithConfirmation
            className="btn btn-danger"
            onClick={() => onDeleteClick(row.original.id)}
            handleYesText={"Delete"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithConfirmation>
        );
      },
      disableFilters: true,
    },
  ];

  const client = new TokensClient();

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const handleCallback = (childData) => {
    hideModal();
    if (isCreate) setTokens([...tokens, childData]);
    else {
      let allTokensCopy = [...tokens];
      let index = allTokensCopy.findIndex((x) => x.id === childData.id);
      allTokensCopy[index] = childData;
      setTokens(allTokensCopy);
    }
  };

  const onCreateClick = () => {
    setRowData(new TokenModel());
    setIsCreate(true);
    showModal();
  };

  const onEditClick = (row) => {
    setRowData(row);
    setIsCreate(false);
    showModal();
  };

  const onDeleteClick = async (id) => {
    await client.removeTokenInfo(id);
    let allTokensCopy = tokens.filter((x) => x.id !== id);
    setTokens(allTokensCopy);
  };

  return (
    <div className="container clearfix">
      <button
        className="btn btn-primary"
        onClick={onCreateClick}
        type="button"
      >
        Create new token
      </button>
      <Table columns={columns} data={tokens} hiddenColumn={["id"]} rememberFilter={false} />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Creation of token" : "Modification of token"}
      >
        <CreateToken
          row={rowData}
          isCreate={isCreate}
          handleCallback={handleCallback}
        />
      </SideModal>
    </div>
  );
};

export default Tokens;
