import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const UserPrivilegesContext = createContext(true);

export const UserPrivilegesProvider = ({ children }) => {
  const { tenantId } = useParams();
  const [userPrivileges, setUserPrivileges] = useState();

  useEffect(() => {
    let hasPrivilege = localStorage.getItem("privilege");
    hasPrivilege && setUserPrivileges(hasPrivilege == "true");
  }, []);

  return (
    <UserPrivilegesContext.Provider
      value={{ userPrivileges, setUserPrivileges }}
    >
      {children}
    </UserPrivilegesContext.Provider>
  );
};

export default UserPrivilegesContext;
