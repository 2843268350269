import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout.component";
import Index from "./pages/index/index.jsx";
import Settings from "./pages/settings/Settings";
import NotificationTargetInfo from "./pages/notification-target-info/NotificationTargetInfo";
import Channels from "./pages/channels/Channels";
import Rules from "./pages/rules/Rules";
import Events from "./pages/events/Events";
import Notifications from "./pages/notifications/Notifications";
import ProtectedRoute from "./validations/protectedRoute";
import PublicRoute from "./validations/publicRoute";
import Login from "./pages/login/Login";
import GeneralSettings from "./pages/general-settings/GeneralSettings";
import Tenants from "./pages/tenants/Tenants.jsx";
import Applications from "./pages/applications/Applications";
import RulesTypes from "./pages/rules-types/RulesTypes";
import Tokens from "./pages/tokens/Tokens";
import NotAllowed from "./pages/not-allowed/NotAllowed.jsx";
import { ApplicationProvider } from "./contexts/ApplicationProvider";
import { RuleTypeProvider } from "./contexts/RuleTypeProvider";
import { TokenProvider } from "./contexts/TokenProvider";

function App() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      ></Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <ApplicationProvider>
              <RuleTypeProvider>
                <TokenProvider>
                  <Layout />
                </TokenProvider>
              </RuleTypeProvider>
            </ApplicationProvider>
          </ProtectedRoute>
        }
      >
        <Route path="/:tenantId" element={<Index />} />
        <Route path="/Settings/:tenantId" element={<Settings />} />
        <Route
          path="/Settings/TargetInfo/:tenantId"
          element={<NotificationTargetInfo />}
        />
        <Route path="/Settings/Channels/:tenantId" element={<Channels />} />
        <Route
          path="/GeneralSettings/:tenantId"
          element={<GeneralSettings />}
        />
        <Route
          path="/GeneralSettings/Tenants/:tenantId"
          element={<Tenants />}
        />
        <Route
          path="/GeneralSettings/Applications/:tenantId"
          element={<Applications />}
        />
        <Route
          path="/GeneralSettings/RulesTypes/:tenantId"
          element={<RulesTypes />}
        />
        <Route path="/GeneralSettings/Tokens/:tenantId" element={<Tokens />} />
        <Route
          path="/Rules/:tenantId&:notificationSpaceId"
          element={<Rules />}
        />
        <Route
          path="/Events/:tenantId&:notificationSpaceId"
          element={<Events />}
        />
        <Route
          path="/Notifications/:tenantId&:notificationSpaceId"
          element={<Notifications />}
        />
      </Route>
      <Route path="/notAllowed" element={<NotAllowed />} />
    </Routes>
  );
}

export default App;
