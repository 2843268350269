import React, { useState, useEffect } from "react";
import Select from "react-select";
import useRulesTypes from "../../hooks/useRulesTypes";
import { TokenModel } from "../../models/Token";
import TokensClient from "../../api-services/tokens/tokens-client";

const CreateToken = ({ row, isCreate, handleCallback }) => {
  const { rulesTypes, setRulesTypes } = useRulesTypes([]);

  const [token, setToken] = useState(new TokenModel());
  const [isCreateToken, setIsCreateToken] = useState(true);

  const mappedRulesTypes = rulesTypes.map((x) => {
    return { value: x.id, label: x.name }
  });

  const client = new TokensClient();

  useEffect(() => {
    setToken(row);
    setIsCreateToken(isCreate)
  }, [row, isCreate]);

  const save = async () => {
    if (isCreateToken) {
      var response = await client.createTokenInfo(token);
    } else {
      var response = await client.updateTokenInfo(token);
    }
    handleCallback(response);
  };

  return (
    <form>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Name</label>
          <input
            className="form-control"
            value={token.tokenName}
            onChange={(event) => setToken({ ...token, tokenName: event.target.value })}
          />
        </div>
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Description</label>
          <input
            className="form-control"
            value={token.description}
            onChange={(event) => setToken({ ...token, description: event.target.value })}
          />
        </div>
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Rule type</label>
          <Select
            options={mappedRulesTypes}
            onChange={(event) => setToken({ ...token, entityTypeId: event.value })
            }
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={mappedRulesTypes.filter((x) => x.value === token.entityTypeId)}
          />
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          type="button"
          onClick={save}
        >
          {isCreateToken ? "Create" : "Save changes"}
        </button>
      </div>
    </form>
  )
};

export default CreateToken;