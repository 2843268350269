import React, {
  useEffect,
  useReducer,
  useState,
  useMemo,
  forwardRef,
  useRef,
} from "react";
import {
  usePagination,
  useTable,
  useSortBy,
  useFilters,
  useRowSelect,
} from "react-table";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  TableScrollContainer,
  PaginateButton,
  FlexDiv,
  PaginateSelect,
} from "./Table.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Row from "./row.component";
import { DefaultColumnFilter } from "./filterHelpFunction.component";
import { useSticky } from "react-table-sticky";
import PropTypes from "prop-types";

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";
const PAGE_SORT_CHANGED = "PAGE_SORT_CHANGED";

const PAGE_KEY = "PAGE_INDEX_";
const PAGE_SIZE_KEY = "PAGE_SIZE_";
const SORT_KEY = "SORT_BY_";

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  queryPageSortBy: [],
  totalCount: 0,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case PAGE_SORT_CHANGED:
      return {
        ...state,
        queryPageSortBy: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const trimData = (data = []) => data.map(({ ...x }) => ({ ...x }));

const mapSortObject = (data = []) =>
  data.map((x) => {
    return { sortDirection: Number(x.desc), columnName: x.id };
  });

function Table({
  rememberFilter,
  tableName = "",
  columns,
  data,
  serverSide,
  fetchFunction,
  setData,
  hiddenColumn,
  rowSortable,
  handleClickOnRow,
  onRowSelectStateChange,
  showPaginateButton = true,
  resetFilters,
  activeTab,
}) {
  const defaultColumn = useMemo(
    () => ({
      disableFilters: true,
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [{ totalCount }, dispatch] = useReducer(reducer, initialState);

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = data[dragIndex];
    setData(
      update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    setAllFilters,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      columns: columns,
      data:
        data == undefined ? [] : serverSide ? trimData(data?.dataList) : data,
      initialState: {
        pageIndex: getPageNumber(tableName, rememberFilter),
        pageSize: getPageSizeNumber(tableName, rememberFilter),
        totalCount: serverSide ? data?.count : data?.length,
        hiddenColumns: hiddenColumn ? hiddenColumn : "",
        selectedRow: null,
        sortBy: getSortBy(tableName, rememberFilter),
        disableSortBy: false,
        rowSortable: false,
      },
      manualPagination: serverSide ? true : false, // Tell the usePagination
      // hook that we'll handle our own data fetching.This means we'll also have to provide our own pageCount.
      manualSortBy: serverSide ? true : false,
      pageCount: Math.ceil(
        totalCount / getPageSizeNumber(tableName, rememberFilter)
      ),
      defaultColumn,
      disableSortBy: rowSortable ? true : false,
      autoResetPage: false,
    },
    serverSide ? "" : useFilters,
    useSortBy,
    usePagination,
    useSticky,
    onRowSelectStateChange ? useRowSelect : "",
    (hooks) => {
      onRowSelectStateChange &&
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: "selection",
              // Make this column a groupByBoundary. This ensures that groupBy columns
              // are placed after it
              groupByBoundary: true,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ];
        });
    }
  );
  const [selectedRow, setSelectedRow] = useState(null);

  const initialRender = useRef(true);
  const resetFiltersRef = useRef(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!rememberFilter) {
      removeTableObjectsFromSessionStorage(tableName);
    }
  }, [rememberFilter]);

  useEffect(() => {
    // mora da se smisli drugi nacin kako da se doda delay samo kada se pageIndex menja (zbog kucanja u inputu za br stranica)
    if (serverSide && !resetFiltersRef.current) {
      const timeout = setTimeout(() => {
        fetchFunction(
          getPageNumber(tableName) + 1,
          getPageSizeNumber(tableName),
          getSortBy(tableName, rememberFilter, serverSide)
        ).then((res) => {
          setData(res);
        });
      }, 100);
      return () => clearTimeout(timeout);
    }
    if (initialRender.current) initialRender.current = false;
    if (resetFiltersRef.current) resetFiltersRef.current = false;
  }, [pageIndex, pageSize, sortBy, activeTab]);

  useEffect(() => {
    setSelectedRow(null);
    rememberFilter && sessionStorage.setItem(PAGE_KEY + tableName, pageIndex);
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    rememberFilter &&
      sessionStorage.setItem(PAGE_SIZE_KEY + tableName, pageSize);
    !rememberFilter && gotoPage(0);
  }, [pageSize]);

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    rememberFilter &&
      sessionStorage.setItem(SORT_KEY + tableName, JSON.stringify(sortBy));
    !rememberFilter && gotoPage(0);
  }, [sortBy]);

  useEffect(() => {
    if (!serverSide && !rememberFilter) {
      dispatch({ type: PAGE_CHANGED, payload: 0 });
      gotoPage(0);
    }
    if (data?.count < pageSize) gotoPage(0);
    (data?.count / pageSize) <= pageIndex && gotoPage(0);
    setSelectedRow(null);
    rowSortable && setData(data);
    resetFilters && resetTableFilter(tableName);
  }, [data]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
    else {
      gotoPage(0);
    }
  }, [pageOptions.length]);

  useEffect(() => {
    //if(!initialRender.current) initialRender.current = true;
    gotoPage(getPageNumber(tableName));
  }, [activeTab]);

  useEffect(() => {
    setSelectedRow(null);
    if (data?.count || data?.length) {
      initialRender.current = true;
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: serverSide ? data.count : data.length,
      });
    }
  }, [data?.length, data?.count]);

  useEffect(
    () => onRowSelectStateChange?.(Object.keys(selectedRowIds)),
    [onRowSelectStateChange, selectedRowIds]
  );

  const onChangePageSize = (e) => {
    gotoPage(0);

    if (Number(e.target.value) === pageSize && rememberFilter && serverSide) {
      sessionStorage.setItem(PAGE_SIZE_KEY + tableName, pageSize);
      fetchFunction(pageIndex + 1, pageSize, mapSortObject(sortBy)).then(
        (res) => {
          !resetFiltersRef.current && setData(res);
        }
      );
    } else setPageSize(Number(e.target.value));
  };

  const resetTableFilter = (tableName) => {
    resetFiltersRef.current = true;
    !serverSide && setAllFilters([]);
    setPageSize(10);
    gotoPage(0);
    setSortBy([]);
    removeTableObjectsFromSessionStorage(tableName);
  };

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const hasSearchFilter = headerGroups.some((headerGroup) =>
    headerGroup.headers.some((column) => column.canFilter)
  );

  return (
    <>
      <TableContainer>
        <div className="clearfix" style={{ marginTop: "10px" }}>
          {(data?.count || data?.length) <= 10 ? (
            <></>
          ) : (
            <PaginateSelect
              value={rememberFilter ? getPageSizeNumber(tableName) : pageSize}
              onChange={(e) => onChangePageSize(e)}
            >
              {[10, 20, 30, 40, 50, 200].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {t("Show")} {pageSize}
                </option>
              ))}
            </PaginateSelect>
          )}
          {!serverSide &&
            (hasSearchFilter || (data?.count || data?.length) > 10) && (
              <button
                type="button"
                className="btn btn-primary pull-right"
                onClick={() => {
                  resetTableFilter(tableName);
                }}
              >
                Reset filters
              </button>
            )}
        </div>

        <TableScrollContainer>
          <DndProvider backend={HTML5Backend}>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {rowSortable && <th style={{ width: "50px" }}></th>}
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className={column.className}
                      >
                        <div {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  className="arrow-down-short-wide"
                                  icon={solid("arrow-down-short-wide")}
                                  pull="right"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="arrow-down-wide-short"
                                  icon={solid("arrow-down-wide-short")}
                                  pull="right"
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 &&
                  (rowSortable
                    ? page.map(
                      (row, index) =>
                        prepareRow(row) || (
                          <Row
                            key={pageSize * pageIndex + index}
                            index={pageSize * pageIndex + index}
                            row={row}
                            moveRow={moveRow}
                          />
                        )
                    )
                    : page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr
                          className={
                            index === selectedRow
                              ? "selectedRow"
                              : "" || typeof handleClickOnRow === "function"
                                ? "pointerRow"
                                : ""
                          }
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => (
                            <td
                              key={index}
                              onClick={
                                cell.value !== undefined
                                  ? () => {
                                    if (
                                      typeof handleClickOnRow === "function"
                                    )
                                      setSelectedRow(index);
                                    return typeof handleClickOnRow ===
                                      "function"
                                      ? handleClickOnRow(row.original)
                                      : undefined;
                                  }
                                  : undefined
                              }
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    }))}
              </tbody>
              {hasSearchFilter && (data?.length > 0 || data?.count > 0) && (
                <tfoot>
                  <tr style={{ width: "100px" }}></tr>
                  {footerGroups.map((group) => (
                    <tr {...group.getFooterGroupProps()}>
                      {rowSortable && <td></td>}
                      {group.headers.map((column) => (
                        <td {...column.getFooterProps()}>
                          {column.canFilter ? column.render("Filter") : null}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              )}
            </table>
          </DndProvider>
        </TableScrollContainer>
        {page.length === 0 && (
          <span className="noResult">{"No results"}</span>
        )}
        {data?.count < pageSize ||
          data?.length < pageSize ||
          !showPaginateButton ? (
          <></>
        ) : (
          <div className="pagination">
            <div>
              {"Page"}&nbsp;
              <strong>
                {rememberFilter ? getPageNumber(tableName) + 1 : pageIndex + 1}{" "}
                {t("of")} {pageOptions.length}
              </strong>
            </div>
            <FlexDiv>
              <PaginateButton
                type="button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon icon={solid("chevron-left")} />
                <FontAwesomeIcon icon={solid("chevron-left")} />
              </PaginateButton>
              <PaginateButton
                type="button"
                onClick={() => {
                  previousPage();
                }}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon icon={solid("chevron-left")} />
              </PaginateButton>
              <input
                className="form-control"
                type="number"
                value={
                  rememberFilter ? getPageNumber(tableName) + 1 : pageIndex + 1
                }
                onChange={(e) => {
                  e.preventDefault();
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "80px" }}
              />
              <PaginateButton
                type="button"
                onClick={() => {
                  nextPage();
                }}
                disabled={!canNextPage}
              >
                <FontAwesomeIcon icon={solid("chevron-right")} />
              </PaginateButton>
              <PaginateButton
                type="button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <FontAwesomeIcon icon={solid("chevron-right")} />
                <FontAwesomeIcon icon={solid("chevron-right")} />
              </PaginateButton>
            </FlexDiv>
          </div>
        )}
      </TableContainer>
    </>
  );
}

export const getPageNumber = (tableName, rememberFilter = true) => {
  if (
    rememberFilter &&
    sessionStorage &&
    parseInt(sessionStorage.getItem(PAGE_KEY + tableName)) > 0
  ) {
    return parseInt(sessionStorage.getItem(PAGE_KEY + tableName));
  } else return 0;
};

export const getPageSizeNumber = (tableName, rememberFilter = true) => {
  if (
    rememberFilter &&
    sessionStorage &&
    parseInt(sessionStorage.getItem(PAGE_SIZE_KEY + tableName)) > 0
  ) {
    return parseInt(sessionStorage.getItem(PAGE_SIZE_KEY + tableName));
  } else return 10;
};

export const getSortBy = (
  tableName,
  rememberFilter = true,
  serverSide = false
) => {
  if (
    rememberFilter &&
    sessionStorage &&
    JSON.parse(sessionStorage.getItem(SORT_KEY + tableName))
  ) {
    if (serverSide)
      return mapSortObject(
        JSON.parse(sessionStorage.getItem(SORT_KEY + tableName))
      );
    else return JSON.parse(sessionStorage.getItem(SORT_KEY + tableName));
  } else return [];
};

export const removeTableObjectsFromSessionStorage = (tableName) => {
  sessionStorage.removeItem(PAGE_KEY + tableName);
  sessionStorage.removeItem(PAGE_SIZE_KEY + tableName);
  sessionStorage.removeItem(SORT_KEY + tableName);
};

Table.propTypes = {
  rememberFilter: PropTypes.bool.isRequired,
  tableName: function (props, propName, componentName) {
    if (props.rememberFilter && !props[propName]) {
      return new Error(
        `The prop '${propName}' is required when 'rememberFilter' is true in '${componentName}'.`
      );
    }
    return null;
  },
};

export default Table;
