import React from "react";

const NotAllowed = () => {
  return (
    <>
      <h2>
        Nemate privilegiju za pristup ovoj stranici!
      </h2>
    </>
  );
};

export default NotAllowed;
