const CreateViberTargerInfo = ({ handleInputChange, value }) => {
  return (
    <div className="form-group row g-2">
      <div className="col-md">
        <label>Viber channel API token</label>
        <input className="form-control"
          type="text"
          name="viberKey"
          onChange={handleInputChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default CreateViberTargerInfo;
