import api from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class NotificationsClient {
    async getNotificationTargetInfosForChannelEvent(tenantId, eventId, channelId) {
        var response = await api.get("/getNotificationTargetInfosWithPhoneNumbersAndMailAddresses?eventId=" + eventId + "&channelId=" + channelId, {
            headers: { tenantId: tenantId },
        });
        return response.data;
    }

    async getNotificationTargetInfos(tenantId, isBackground) {
        var response = await api.get("/getAllNotificationTargetInfos", {
            headers: { tenantId: tenantId },
            isBackground: isBackground
        });
        return response.data;
    }

    async createNotification(tenantId, data) {
        const headers = {
            'Content-Type': 'application/json',
            'tenantId': tenantId,
        };
        try {
            var response = await api.post('/createNotification', data, {
                headers: headers
            });
            if (response.status !== 200)
                Error(response.message);
            else
                Success("Successfully!");
        }
        catch (err) {
            Error(err.message);
        }
    }

    async removeEventChannelBehaviourTarget(tenantId, eventChannelBehaviourTargetId) {
        var response = await api.delete(
            "/removeEventChannelBehaviourTarget?eventChannelBehaviourTargetId=" +
            eventChannelBehaviourTargetId, {
            headers: { tenantId: tenantId },
        }
        );
        if (response.status !== 200)
            Error(response.message);
        else
            Success("Successfully!");
    }

    async updateNotificationStatus(tenantId, data) {
        var response = await api.post(
            "/updateEventChannelBehaviourTargetByStatus", data, {
            headers: { tenantId: tenantId },
        }
        );
        if (response.status !== 200)
            Error(response.message);
        else
            Success("Successfully!");
    }

    async getTargetInfos(tenantId, isBackground) {
        var response = await api.get("/GetNotificationTargetInfos", {
            headers: { tenantId: tenantId },
            isBackground: isBackground
        });
        return response.data;
    }
}

export default NotificationsClient;