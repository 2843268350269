import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Editor from "@monaco-editor/react";
import Select from "react-select";
import { Channel, ChannelTypes } from "../../models/Channel";
import ChannelsClient from '../../api-services/channels/channels-client';

const CreateNewChannel = ({ row, handleCallback, isEdit }) => {
  const { tenantId } = useParams();

  const [channel, setChannel] = useState(new Channel());

  var client = new ChannelsClient();

  useEffect(() => {
    setChannel(row);
  }, [row]);

  const save = async () => {
    if (isEdit) {
      var response = await client.updateChannel(tenantId, channel);
    } else {
      var response = await client.createChannel(tenantId, channel);
    }
    handleCallback(response);
  }

  return (
    <>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Channel type</label>
          <Select
            options={ChannelTypes}
            onChange={(event) => setChannel({ ...channel, channelType: event.value })}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={ChannelTypes.filter((x) => x.value === channel.channelType)}
          />
        </div>
        <div className="col-md">
          <label>Channel name</label>
          <input
            type="text"
            className="form-control"
            value={channel.name}
            onChange={(event) => setChannel({ ...channel, name: event.target.value })}
          />
        </div>
      </div>
      <div className=" form-group row g-2">
        <label>Conf JSON</label>
        <Editor height="250px"
          language="json"
          value={channel.configuration}
          onChange={(event) => setChannel({ ...channel, configuration: event })}
        />
      </div>
      <div className="text-right">
        <button className="btn btn-primary m-2" onClick={save}>
          {isEdit ? "Save changes" : "Create"}
        </button>
      </div>
    </>
  );
};

export default CreateNewChannel;
