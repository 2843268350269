class TokenModel {
    constructor(
        id = 0,
        tokenName = "",
        description = "",
        entityTypeId = ""
    ) {
        this.id = id;
        this.tokenName = tokenName;
        this.description = description;
        this.entityTypeId = entityTypeId;
    }
}

export { TokenModel };
