import { createContext, useEffect, useState } from "react";

const NavContext = createContext(true);

export const NavProvider = ({children}) => {
    const [sidebar, setSidebar] = useState(true);

    useEffect(() => {
        let navState = localStorage.getItem('sidebar');
        var isTrueSet = (navState === 'true');
        if (navState)
          setSidebar(isTrueSet);
      }, [])

      useEffect(() => {
        if (!sidebar) {
            localStorage.removeItem('sidebar')
        }
        else {
            localStorage.setItem('sidebar', sidebar)
        }
      }, [sidebar])

    

    return (
        <NavContext.Provider value={{ sidebar, setSidebar }}>
            {children}
        </NavContext.Provider>
    )
}

export default NavContext;