import UserService from "../../utils/authentification/keycloak";

const Login = () => {
    return (
        <div style={{ textAlign: "center" }}>
            <h3>Log in</h3>
            <button className="btn btn-primary m-2" onClick={() => UserService.doLogin({ redirectUri: window.location.origin + '/' })}>Log in</button>
        </div>
    )
}

export default Login;