import useUserPrivileges from "../hooks/useUserPrivileges";

const RenderOnPrivilege = ({ children }) => {
  const { userPrivileges, setUserPrivileges } = useUserPrivileges();

  if (userPrivileges) return children;
  else return null;
};

export default RenderOnPrivilege;
