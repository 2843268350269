import styled from "styled-components";

export const NewUserInfoDiv = styled.div`
  position: relative;
  margin-bottom: 15px;
  button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    color: red;
    padding: 6px 12px;
    font-size: 18px;
  }
`;
