import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { NotificationContainer } from "./CreateNotification.style";
import { SwitchContainer } from "../../common/switch/Switch.style";
import Select from "react-select";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import Table from "../../common/table/Table.component";
import { SelectColumnFilter } from "../../common/table/filterHelpFunction.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DialogModal from "../../common/modal/Modal.component";
import CreateTarget from "../create-target/CreateTarget.component";
import ChannelsClient from "../../api-services/channels/channels-client";
import NotificationsClient from "../../api-services/notifications/notifications-client";

const CreateNotification = ({ chosenEvent }) => {
  const { tenantId } = useParams();
  const { notificationSpaceId } = useParams();

  const [event, setEvent] = useState(null);
  const [channels, setChannels] = useState([]);
  const [channel, setChannel] = useState({ id: "", name: "", type: "" });

  const [rows, setRows] = useState([]);
  const [show, setShow] = useState(false);

  const filter = {
    filterValue: "includes",
    selectOptions: [
      { value: undefined, label: "All" },
      { value: "false", label: "Inactive" },
      { value: "true", label: "Active" },
    ],
  };

  const columns = [
    {
      Header: "Target name",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "E-mail",
      accessor: "mailAddresses",
      disableFilters: false,
    },
    {
      Header: "Phone number",
      accessor: "phoneNumbers",
      disableFilters: false,
    },
    {
      Header: "Status",
      accessor: "statusLabel",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return row.original.statusLabel
          ? "Active"
          : "Inactive";
      },
    },
    {
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <SwitchContainer>
            <label htmlFor={"switch" + row.original.id}>
              <input
                id={"switch" + row.original.id}
                type="checkbox"
                checked={row.original.status}
                value={row.original.status}
                onChange={(e) =>
                  onStatusChange(row.original, e.target.checked)
                }
              />
              <span className="indicator"></span>
            </label>
          </SwitchContainer>
        );
      },
      disableFilters: true,
    },
    {
      accessor: "remove",
      Cell: ({ row }) => {
        return (
          <ButtonWithConfirmation
            className="btn btn-danger"
            onClick={() => onDeleteClick(row.original.id)}
            handleYesText={"Delete"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithConfirmation>
        );
      },
      disableFilters: true,
    },
  ];

  const client = new ChannelsClient();
  const nClient = new NotificationsClient();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  useEffect(() => {
    if (tenantId) {
      getChannels();
    }
  }, []);

  useEffect(() => {
    setEvent(chosenEvent);
  }, [chosenEvent]);

  useEffect(() => {
    if (event && channel.name != "") {
      getTableData();
    }
  }, [event, channel.name]);

  const getChannels = async () => {
    var response = await client.getAllChannels(tenantId);
    if (response && response != "Error") {
      let data = response.map(x => {
        return { value: x.id, label: x.name, type: x.channelType }
      })
      setChannels(data);
    }
  };

  const getTableData = async () => {
    let response = await nClient.getNotificationTargetInfosForChannelEvent(tenantId, event, channel.id);
    var data = response.map((item) => {
      return {
        ...item,
        name: item.notificationTargetInfo?.name,
        mailAddresses: item.mailAddress?.email,
        phoneNumbers: item.phoneNumber?.phoneNr,
        statusLabel: item.status,
      };
    });
    setRows(data);
  };

  const onStatusChange = (row, value) => {
    var tableCopy = [...rows];
    var index = tableCopy.indexOf(row);
    tableCopy[index].status = value;
    tableCopy[index].statusLabel = value;
    setRows(tableCopy);
    var data = {
      id: row.id,
      eventChannelBehaviourId: row.eventChannelBehaviourId,
      notificationTargetId: row.notificationTargetId,
      status: value,
    };
    nClient.updateNotificationStatus(tenantId, data);
  };

  const onDeleteClick = async (eventChannelBehaviorTargetId) => {
    await nClient.removeEventChannelBehaviourTarget(
      tenantId,
      eventChannelBehaviorTargetId
    );
    getTableData();
  };

  const handleCreate = () => {
    HideDialog();
    getTableData();
  }

  return notificationSpaceId ? (
    <NotificationContainer>
      <div className="nav-n-table">
        <div className="form-group">
          <label>Choose channel</label>
          <Select
            options={channels}
            onChange={(e) => {
              setChannel({ id: e.value, name: e.label, type: e.channelType });
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            value={channels.filter((x) => x.value === channel.id)}
          />
        </div>
        {event && channel.id !== "" && (
          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={ShowDialog}
            >
              Add new target
            </button>
            <Table columns={columns} data={rows} rememberFilter={false} />
          </div>
        )}
      </div>
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={"Creation of target"}
      >
        <CreateTarget
          channel={channel}
          event={event}
          handleCreate={handleCreate}
        />
      </DialogModal>
    </NotificationContainer>
  ) : (
    <Navigate to="/" />
  );
};

export default CreateNotification;
