const TargetInfoTypes = {
    User: {
        id: 1,
        title: "User"
    },
    EmailGroup: {
        id: 2,
        title: "Email Group"
    },
    TeamsChannel: {
        id: 3,
        title: "Teams Channel"
    },
    ViberChannel: {
        id: 4,
        title: "Viber Channel"
    },
}

class EmailAddress {
    constructor(id = 0, notificationTargetInfoId = TargetInfoTypes.User.id, email = "", defaultMailAddress = true) {
        this.id = id;
        this.notificationTargetInfoId = notificationTargetInfoId;
        this.email = email;
        this.defaultMailAddress = defaultMailAddress;
    }
}

class PhoneNumber {
    constructor(id = 0, notificationTargetInfoId = TargetInfoTypes.User.id, phoneNr = "", defaultPhoneNumber = true) {
        this.id = id;
        this.notificationTargetInfoId = notificationTargetInfoId;
        this.phoneNr = phoneNr;
        this.defaultPhoneNumber = defaultPhoneNumber;
    }
}

class NotificationSpaceUserGroupIdentifier {
    constructor(id = 0, externalUserIdentifier = "", notificationTargetInfoId = TargetInfoTypes.User.id, notificationSpaceId = 0, userIdentificator = true, name = "") {
        this.id = id;
        this.externalUserIdentifier = externalUserIdentifier;
        this.notificationTargetInfoId = notificationTargetInfoId;
        this.notificationSpaceId = notificationSpaceId;
        this.userIdentificator = userIdentificator;
        this.name = name;
    }
}

class NotificationTargetInfo {
    constructor(id = 1, notificationTargetType = TargetInfoTypes.User.id, name = '', firstName = '', lastName = '', phoneNumbers = [], mailAddresses = [], teamsKey = '', viberKey = '', viberSenderId = '', groupName = '', notificationSpaceUserGroupIdentifiers = []) {
        this.id = id;
        this.notificationTargetType = parseInt(notificationTargetType);
        this.name = name;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumbers = phoneNumbers;
        this.mailAddresses = mailAddresses;
        this.teamsKey = teamsKey;
        this.viberKey = viberKey;
        this.viberSenderId = viberSenderId;
        this.groupName = groupName;
        this.notificationSpaceUserGroupIdentifiers = notificationSpaceUserGroupIdentifiers;
    }
}

export { NotificationTargetInfo, TargetInfoTypes, EmailAddress, PhoneNumber, NotificationSpaceUserGroupIdentifier };