import { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import PublicRoute from "./publicRoute";
import UserService from "../utils/authentification/keycloak";
import useUserPrivileges from "../hooks/useUserPrivileges";
import { getPrivilegesForLocation } from "./getPrivilegesForLocation";

const ProtectedRoute = ({ children }) => {
  useNavigate();
  const { tenantId } = useParams();
  const { userPrivileges, setUserPrivileges } = useUserPrivileges();
  const [hasPrivilege, setHasPrivilege] = useState(true);

  useEffect(() => {
    if (tenantId && userPrivileges != undefined) {
      checkPrivileges();
    }
  }, [tenantId, userPrivileges]);

  const checkPrivileges = () => {
    var privileges = getPrivilegesForLocation(window.location.pathname, userPrivileges);
    setHasPrivilege(privileges);
  };

  if (UserService.isLoggedIn()) {
    if (hasPrivilege) return children;
    else return <Navigate replace to={"/notAllowed"} />;
  } else {
    return <PublicRoute url={window.location.pathname} />;
  }
};

export default ProtectedRoute;
