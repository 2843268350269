import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../common/table/Table.component";
import DialogModal from "../../common/modal/Modal.component";
import SideModal from "../../common/modal/SideModal.component";
import SearchEvents from "../../components/search-events/searchEvents.component";
import CreateNotification from "../../components/create-notification/CreateNotification.component";
import EventsClient from "../../api-services/events/events-client";

const Notifications = () => {
  const { tenantId } = useParams();
  const { notificationSpaceId } = useParams();

  const tableName = "Notifications";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);
  const [filter, setFilter] = useState({
    eventName: "",
    notificationTargetInfoName: null,
    eventChannelBehaviourTargetStatus: null,
    notificationSpaceId: parseInt(notificationSpaceId),
  });

  const [events, setEvents] = useState([]);
  const [chosenEvent, setChosenEvent] = useState();
  const [show, setShow] = useState(false);

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Event",
      accessor: "name",
    },
    {
      Header: "Subject template",
      accessor: "subjectTemplate",
    }
  ];

  const eClient = new EventsClient();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  useEffect(() => {
    reset && setReset(false);
  }, [events]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    var response = await eClient.filterEvents(tenantId, {
      ...filter,
      pageSize: pageSize,
      pageNumber: pageNumber,
    });

    return { dataList: response.events, count: response.count };
  };

  const searchData = async () => {
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setEvents(result);
  };

  const resetFilters = async () => {
    setReset(true);
    let data = {
      eventName: "",
      notificationTargetInfoName: null,
      eventChannelBehaviourTargetStatus: null,
      notificationSpaceId: parseInt(notificationSpaceId),
    };
    setFilter(data);
    var response = await eClient.filterEvents(tenantId, {
      ...data,
      pageSize: 10,
      pageNumber: 1,
    });
    setEvents({ dataList: response.events, count: response.count });
  };

  const handleTableRowClick = useCallback((response) => {
    setChosenEvent(response.id);
    ShowDialog();
  });

  return (
    <div className="container clearfix">
      <SearchEvents
        filterData={filter}
        setFilterData={setFilter}
        handleCallback={searchData}
        resetFilters={resetFilters}
      />
      <Table
        columns={columns}
        data={events}
        tableName={tableName}
        rememberFilter={rememberFilter}
        hiddenColumn={"id"}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setEvents}
        resetFilters={reset}
        handleClickOnRow={handleTableRowClick}
      />
      <SideModal
        show={show}
        handleClose={HideDialog}
        title={"Creation of notifications"}
      >
        <CreateNotification chosenEvent={chosenEvent} />
      </SideModal>
    </div>
  );
};

export default Notifications;
