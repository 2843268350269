import { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { numberTypeInputs } from "./field-types";
import { hasDuplicateValues } from "../../../utils/array-methods/array-methods";
import { Error } from "../../../common/toastify/toastify";
import { NotificationTargetInfo, TargetInfoTypes } from "../../../models/NotificationTargetInfo";
import CreateUserTargerInfo from "../create-user-target-info/create-user-target-info";
import CreateViberTargerInfo from "../create-viber-target-info/create-viber-target-info";
import CreateTeamsTargerInfo from "../create-teams-target-info/create-teams-target-info";
import CreateEmailGroupTargerInfo from "../create-email-group-target-info/create-email-group-target-info";
import NotificationTargetInfoClient from "../../../api-services/notification-target-info/notification-target-info-client";

const CreateNotificationTargetInfo = ({ targetInfoForEdit, handleSubmitAction }) => {
    const { tenantId } = useParams();

    const [formData, setFormData] = useState(new NotificationTargetInfo())
    const client = new NotificationTargetInfoClient();

    useEffect(() => {
        if (targetInfoForEdit) {
            setFormData(targetInfoForEdit);
        }
        else {
            setFormData(new NotificationTargetInfo());
        }
    }, [targetInfoForEdit])

    const handleInputChange = useCallback(e => {
        let target = e.target;
        let value = numberTypeInputs.includes(target.name) ? Number(target.value.trim()) : target.value.trim();
        setFormData({
            ...formData,
            [target.name]: value
        })
    }, [formData])

    const handleArrayChange = useCallback((name, array) => {
        setFormData({
            ...formData,
            [name]: array
        })
    }, [formData])

    const submitTargetInfo = async e => {
        e.preventDefault();
        let result = null;

        if (hasDuplicateValues(formData.mailAddresses.map(x => x.email))) {
            Error("E-mail fields must have unique values")
            return;
        }

        if (hasDuplicateValues(formData.phoneNumbers.map(x => x.phoneNr))) {
            Error("Phone number fields must have unique values")
            return;
        }

        if (!targetInfoForEdit) {
            result = await client.createNotificationTargetInfo(tenantId, formData);
            handleSubmitAction(true, result);
        }
        else {
            result = await client.updateNotificationTargetInfo(tenantId, formData);
            handleSubmitAction(false, result);
        }
    }

    return (
        <form onSubmit={submitTargetInfo}>
            <div className="form-group row g-2">
                <div className="col-md">
                    <label>NT type</label>
                    <select
                        className="form-control"
                        name="notificationTargetType"
                        onChange={handleInputChange}
                        value={formData?.notificationTargetType}
                    >
                        {
                            Object.keys(TargetInfoTypes).map(x => {
                                return <option key={TargetInfoTypes[x].id} value={TargetInfoTypes[x].id}>{TargetInfoTypes[x].title}</option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md">
                    <label>Name</label>
                    <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={handleInputChange}
                        value={formData?.name}
                    />
                </div>
            </div>
            {formData.notificationTargetType.toString() === TargetInfoTypes.User.id.toString() &&
                <CreateUserTargerInfo
                    handleInputChange={handleInputChange}
                    handleArrayChange={handleArrayChange}
                    firstName={formData.firstName}
                    lastName={formData.lastName}
                    mailAddresses={formData.mailAddresses}
                    phoneNumbers={formData.phoneNumbers}
                    notificationSpaceUserGroupIdentifiers={formData.notificationSpaceUserGroupIdentifiers}
                />}
            {formData.notificationTargetType.toString() === TargetInfoTypes.EmailGroup.id.toString() &&
                <CreateEmailGroupTargerInfo
                    handleArrayChange={handleArrayChange}
                    mailAddresses={formData.mailAddresses}
                />}
            {formData.notificationTargetType.toString() === TargetInfoTypes.TeamsChannel.id.toString() &&
                <CreateTeamsTargerInfo
                    handleInputChange={handleInputChange}
                    value={formData.teamsKey}
                />}
            {formData.notificationTargetType.toString() === TargetInfoTypes.ViberChannel.id.toString() &&
                <CreateViberTargerInfo
                    handleInputChange={handleInputChange}
                    value={formData.viberKey}
                />}
            <div className="text-right">
                <button className="btn btn-primary" type="submit">Save</button>
            </div>
        </form>
    )
}

export default CreateNotificationTargetInfo;