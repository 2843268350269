import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../common/table/Table.component";
import SideModal from "../../common/modal/SideModal.component";
import { TenantModel } from "../../models/Tenant";
import CreateTenant from "../../components/create-tenant/CreateTenant.component";
import TenantsClient from "../../api-services/tenants/tenants-client";

const Tenants = () => {
    const [tenants, setTenants] = useState([]);

    const [rowData, setRowData] = useState(new TenantModel());
    const [isCreate, setIsCreate] = useState(true);
    const [show, setShow] = useState(false);

    const columns = [
        {
            Header: "Tenant id",
            accessor: "id",
        },
        {
            Header: "Tenant name",
            accessor: "tenantName",
            disableFilters: false
        },
        {
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <button
                        className="btn btn-primary table-btn"
                        onClick={() => onEditClick(row.original)}
                    >
                        <FontAwesomeIcon icon={solid("pen-to-square")} />
                    </button>
                );
            },
            disableFilters: true
        }
    ];

    const client = new TenantsClient();

    const showModal = () => setShow(true);
    const hideModal = () => setShow(false);

    useEffect(() => {
        getAllTenants();
    }, []);

    const getAllTenants = async () => {
        let response = await client.getAllTenants();
        setTenants(response);
    };

    const handleCallback = (childData) => {
        hideModal();
        if (isCreate) setTenants([...tenants, childData]);
        else {
            let allTenantsCopy = [...tenants];
            let index = allTenantsCopy.findIndex((x) => x.id === childData.id);
            allTenantsCopy[index] = childData;
            setTenants(allTenantsCopy);
        }
    }

    const onCreateClick = () => {
        setRowData(new TenantModel());
        setIsCreate(true);
        showModal();
    };

    const onEditClick = (row) => {
        setRowData(row);
        setIsCreate(false);
        showModal();
    };

    return (
        <div className="container clearfix">
            <button
                className="btn btn-primary"
                onClick={onCreateClick}
                type="button"
            >
                Create new tenant
            </button>
            <Table
                columns={columns}
                data={tenants}
                hiddenColumn={["id"]}
                rememberFilter={false}
            />
            <SideModal
                show={show}
                handleClose={hideModal}
                title={isCreate ? "Creation of tenant" : "Modification of tenant"}
            >
                <CreateTenant
                    row={rowData}
                    isCreate={isCreate}
                    handleCallback={handleCallback}
                />
            </SideModal>
        </div>
    );
};

export default Tenants;