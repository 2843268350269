const ChannelTypes = [
  {
    value: 1,
    label: "Email",
  },
  {
    value: 2,
    label: "SMS",
  },
  {
    value: 3,
    label: "Viber",
  },
  {
    value: 4,
    label: "Teams",
  },
  {
    value: 5,
    label: "Webhook"
  }
];


class Channel {
  constructor(id = 0, channelType = "", channelTypeLabel = "", name = "", configuration = "") {
    this.id = id;
    this.channelType = channelType;
    this.channelTypeLabel = channelTypeLabel;
    this.name = name;
    this.configuration = configuration;
  }
}

export { Channel, ChannelTypes }