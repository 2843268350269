import { SwitchContainer } from "./Switch.style";

const Switch = ({ label, value, onChange }) => {

    return(
        <>
            <SwitchContainer>
                <label htmlFor="switch">
                    <span className="label">{label}</span>
                    <input
                        id="switch"
                        type="checkbox"
                        checked={value}
                        value={value}
                        onChange={(event) => onChange(event.target.checked)}
                    />
                    <span className="indicator"></span>
                </label>
            </SwitchContainer>
        </>
    )
}

export default Switch;