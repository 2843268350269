import api from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class TokensClient {

    async getAllTokenInfos() {
        var response = await api.get("/getAllTokenInfos");
        return response.data;
    }

    async createTokenInfo(data) {
        try {
            var response = await api.post("/createTokenInfo", data);
            if (response.status !== 200)
                Error(response.message);
            else {
                Success("Successfully!");
                return response.data;
            }
        }
        catch (err) {
            Error(err.message);
        }
    }

    async updateTokenInfo(data) {
        try {
            var response = await api.post("/updateTokenInfo", data);
            if (response.status !== 200)
                Error(response.message);
            else {
                Success("Successfully!");
                return response.data;
            }
        }
        catch (err) {
            Error(err.message);
        }
    }

    async removeTokenInfo(tokenInfoId) {
        try {
            var response = await api.delete("/removeTokenInfo?tokenInfoId=" + tokenInfoId);
            if (response.status !== 200)
                Error(response.message);
            else
                Success("Successfully!");
        }
        catch (err) {
            Error(err.message);
        }
    }

}

export default TokensClient;