const CreateTeamsTargerInfo = ({ handleInputChange, value }) => {
    return (
        <div className="form-gorup row g-2">
            <div className="col-md">
                <label>Microsoft Teams channel token</label>
                <input
                    className="form-control"
                    type="text"
                    name="teamsKey"
                    onChange={handleInputChange}
                    value={value}
                />
            </div>
        </div>
    )
};

export default CreateTeamsTargerInfo;