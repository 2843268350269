import styled from "styled-components";

export const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  table {
    width: 100%;
    border-spacing: 0;
    tr th {
      border-bottom: 2px solid #333;
      text-align: left;
    }
    tr td {
      border-bottom: 1px solid #e5e5e5;
      text-align: left;
    }
  }
  .pagination {
    padding: 0.5rem;
    width: 100%;
  }
`;

export const NavBarButton = styled.button`
  border: none;
  background-color: #fff;
  font-size: 24px;
  margin: 0px 8px;
`;
export const SideNavBar = styled.div`
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid #ddd;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 7px 9px -3px rgb(0 0 0 / 50%);

  span {
    display: block;
    width: 100%;
    height: 34px;
  }

  &.collapsed {
    width: 50px;
    transition: all 0.2s ease-in-out;
  }

  a {
    display: flex;
    align-items: center;
    padding-left: 15px;
  }

  a span {
    padding: 5px 8px;
  }

  a.active {
    background-color: #0095f6;
    color: #fff;
  }

  a.active span {
    background-color: #0095f6;
    color: #fff;
  }
  
`;

export const LogoButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SubLogoDiv = styled.div`
  margin-top: 15px;
  margin-bottom: 35px;
  text-align: center;
  cursor: pointer;
  a {
    padding-left: 0;
    display: block;
  }
`
