import styled from "styled-components";

export const LoaderContainer = styled.div`
.loader-div {
    align-items: flex-end;
    justify-content: center;
    position:absolute;
    top: 1%;
    left: 87%;
    z-index: 9999;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;

}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`