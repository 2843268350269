import styled from "styled-components";

export const NotificationSpaceContainer = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  width: 300px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0px 7px 9px -9px rgb(0 0 0 / 50%);
  cursor: pointer;
  transition: 0.2s ease-in-out;

  .notification-space-container {
    word-wrap: break-word;
  }

  .n-s-title {
    transform: translate(0px, 20px);
    transition: all 0.2s ease-in-out;
  }

  button {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: translate(0px, -20px);
  }

  a {
    text-decoration: none;
    color: #555;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    .n-s-title {
      transform: translate(0px, 0px);
      transition: all 0.2s ease-in-out;
    }

    border: 1px solid #0095f6;
    a {
      color: #0095f6;
      transition: all 0.2s ease-in-out;
    }
    button {
      opacity: 1;
      transform: translate(0px, 0px);
      transition: all 0.2s ease-in-out;
    }
  }

  .n-s-title {
    text-align: center;
    font-weight: bold;
    columns: #333;
    font-size: 25px;
  }
  .n-s-linked-app {
    text-align: center;
  }
  .n-s-key {
    text-align: center;
    margin-top: 15px;
  }
`;
