import API from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class EventsClient {
  async getAllEvents(tenantId, notificationSpaceId) {
    var response = await API.get(
      "/getAllEvents?tenantId=" +
        tenantId +
        "&notificationSpaceId=" +
        notificationSpaceId
    );
    return response.data;
  }

  async getEventsWithRules(tenantId, notificationSpaceId) {
    var response = await API.get(
      "/getEventsWithRules?tenantId=" +
        tenantId +
        "&notificationSpaceId=" +
        notificationSpaceId
    );
    return response.data;
  }

  async createEventWithRule(tenantId, data) {
    const headers = {
      "Content-Type": "application/json",
      tenantId: tenantId,
    };
    try {
      var response = await API.post("/createEventWithRule", data, {
        headers: headers,
      });
      if (response.status !== 200) {
        Error(response.message);
      } else {
        Success("Successfully!");
        return response.data;
      }
    } catch (err) {
      Error(err.message);
    }
  }

  async updateEventWithRule(tenantId, data) {
    const headers = {
      "Content-Type": "application/json",
      tenantId: tenantId,
    };
    try {
      var response = await API.post("/updateEventWithRule", data, {
        headers: headers,
      });
      if (response.status !== 200) {
        Error(response.message);
      } else {
        Success("Successfully!");
        return response.data;
      }
    } catch (err) {
      Error(err.message);
    }
  }

  async removeEvent(tenantId, eventId) {
    var response = await API.delete("/removeEvent?eventId=" + eventId, {
      headers: { tenantId: tenantId },
    });
    if (response.status !== 200) Error(response.message);
    else Success("Successfully!");
  }

  async filterEvents(tenantId, data) {
    const headers = {
      "Content-Type": "application/json",
      tenantId: tenantId,
    };
    try {
      var response = await API.post("/filterEvents", data, {
        headers: headers,
      });
      if (response.status !== 200) {
        Error(response.message);
      } else {
        return response.data;
      }
    } catch (err) {
      Error(err.message);
    }
  }
}

export default EventsClient;
