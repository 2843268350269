import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../common/table/Table.component";
import { SelectColumnFilter } from "../../common/table/filterHelpFunction.component";
import SideModal from "../../common/modal/SideModal.component";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import { TargetInfoTypes } from "../../models/NotificationTargetInfo";
import CreateNotificationTargetInfo from "../../components/notification-target-info/create-notification-target-info/CreateNotificationTargetInfo.component";
import NotificationTargetInfoClient from "../../api-services/notification-target-info/notification-target-info-client";

const NotificationTargetInfo = () => {
  const { tenantId } = useParams();

  const [targetInfos, setTargetInfos] = useState([]);
  const [showCreateTargetInfoModal, setShowCreateTargetInfoModal] = useState(false);
  const [targetInfoForEdit, setTargetInfoForEdit] = useState(null);

  const filter = {
    filterValue: "equals",
    selectOptions:
      Object.keys(TargetInfoTypes).map((x) => {
        return (
          {
            value: TargetInfoTypes[x].id,
            label: TargetInfoTypes[x].title
          }
        );
      })
  };

  filter.selectOptions.unshift({ value: undefined, label: "All" });

  const columns = [
    {
      Header: "NT type",
      accessor: "notificationTargetType",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return TargetInfoTypes[
          Object.keys(TargetInfoTypes).filter(
            (x) => TargetInfoTypes[x].id === row.original.notificationTargetType
          )[0]
        ].title;
      },
    },
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "User's first name",
      accessor: "firstName",
      id: "firstName",
      disableFilters: false,
    },
    {
      Header: "User's last name",
      accessor: "lastName",
      disableFilters: false,
    },
    {
      accessor: "edit",
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
      disableFilters: true,
    },
    {
      accessor: "delete",
      Cell: ({ row }) => {
        return (
          <ButtonWithConfirmation
            className="btn btn-danger"
            onClick={() => onDeleteClick(row.original.id)}
            handleYesText={"Delete"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithConfirmation>
        );
      },
      disableFilters: true,
    },
  ];

  const client = new NotificationTargetInfoClient();

  useEffect(() => {
    if (tenantId) {
      getAllNotificationTargetInfos();
    }
  }, []);

  const getAllNotificationTargetInfos = async () => {
    let data = await client.getAllNotificationTargetInfos(tenantId);
    setTargetInfos(data);
  }

  const onCreateClick = () => {
    setTargetInfoForEdit(null);
    setShowCreateTargetInfoModal(true);
  };

  const onEditClick = (targetInfo) => {
    setTargetInfoForEdit(targetInfo);
    setShowCreateTargetInfoModal(true);
  };

  const onDeleteClick = async (notificationTargetInfoId) => {
    await client.removeNotificationTargetInfo(
      tenantId,
      notificationTargetInfoId
    );
    let targetInfosCopy = targetInfos.filter(
      (x) => x.id !== notificationTargetInfoId
    );
    setTargetInfos(targetInfosCopy);
  };

  const handleSubmitAction = (isNewElement, element) => {
    if (isNewElement) {
      targetInfos.push(element);
      setTargetInfos(targetInfos);
    } else {
      let targetInfosClone = [...targetInfos];
      let elementIndex = targetInfosClone.findIndex((x) => x.id === element.id);
      if (elementIndex > -1) {
        targetInfosClone[elementIndex] = structuredClone(element);
      }
      setTargetInfos(targetInfosClone);
    }
    setShowCreateTargetInfoModal(false);
  };

  return tenantId ? (
    <div className="container clearfix">
      <div>
        <h4 className="text-center">
          Settings {">"} Notification target info
        </h4>
        <button className="btn btn-primary" onClick={onCreateClick}>
          Create new target info
        </button>
        <Table columns={columns} data={targetInfos} rememberFilter={false} />
      </div>
      <SideModal
        title={targetInfoForEdit ? "Modification of notification target info" : "Creation of notification target info"}
        handleClose={() => setShowCreateTargetInfoModal(false)}
        show={showCreateTargetInfoModal}
      >
        <CreateNotificationTargetInfo
          targetInfoForEdit={targetInfoForEdit}
          handleSubmitAction={handleSubmitAction}
        />
      </SideModal>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default NotificationTargetInfo;
