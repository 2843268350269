import { createContext, useEffect, useState } from "react";
import RulesTypesClient from "../api-services/rules-types/rules-types-client";

const RuleTypeContext = createContext(true);

export const RuleTypeProvider = ({ children }) => {
    const [rulesTypes, setRulesTypes] = useState([]);

    const client = new RulesTypesClient();

    const getAllRulesTypes = async () => {
        await client.getAllEntityTypes().then((response) => {
            setRulesTypes(response);
        });
    }

    useEffect(() => {
        getAllRulesTypes();
    }, []);

    return(
        <RuleTypeContext.Provider value={{rulesTypes, setRulesTypes}}>
            {children}
        </RuleTypeContext.Provider>
    )
};

export default RuleTypeContext;