import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EventModel } from "../../models/Event";
import SideModal from "../../common/modal/SideModal.component";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import Table from "../../common/table/Table.component";
import { SelectColumnFilter } from "../../common/table/filterHelpFunction.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CreateEvent from "../../components/create-event/CreateEvent.component";
import EventsClient from "../../api-services/events/events-client";

const Events = () => {
  const { tenantId } = useParams();
  const { notificationSpaceId } = useParams();

  const [rowData, setRowData] = useState(new EventModel());
  const [allEvents, setAllEvents] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [show, setShow] = useState(false);

  const filter = {
    filterValue: "equals",
    selectOptions: [
      { value: undefined, label: "All" },
      { value: "false", label: "Inactive" },
      { value: "true", label: "Active" },
    ]
  };

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Subject",
      accessor: "subjectTemplate",
      disableFilters: false,
    },
    {
      Header: "Event template",
      accessor: "bodyTemplate",
      disableFilters: false,
    },
    {
      Header: "Rule name",
      accessor: "ruleName",
      disableFilters: false,
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return row.original.status ? "Active" : "Inactive";
      },
    },
    {
      accessor: "edit",
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
      disableFilters: true,
    },
    {
      accessor: "delete",
      Cell: ({ row }) => {
        return (
          <ButtonWithConfirmation
            className="btn btn-danger"
            onClick={() => onDeleteClick(row.original.id)}
            handleYesText={"Delete"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithConfirmation>
        );
      },
      disableFilters: true,
    },
  ];

  var client = new EventsClient();

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  useEffect(() => {
    if (tenantId && notificationSpaceId) {
      getEventsList();
    }
  }, []);

  const getEventsList = async () => {
    let response = await client.getEventsWithRules(tenantId, notificationSpaceId);
    setAllEvents(response);
  };

  const onCreateClick = () => {
    setIsCreate(true);
    setRowData(new EventModel());
    showModal();
  };

  const onEditClick = (row) => {
    setIsCreate(false);
    setRowData(row);
    showModal();
  };

  const onDeleteClick = async (eventId) => {
    await client.removeEvent(tenantId, eventId);
    let eventsCopy = allEvents.filter((x) => x.id !== eventId);
    setAllEvents(eventsCopy);
  };

  const handleCallback = (childData) => {
    hideModal();
    if (isCreate) setAllEvents([...allEvents, childData]);
    else {
      let allEventsCopy = [...allEvents];
      let index = allEventsCopy.findIndex((x) => x.id === childData.id);
      allEventsCopy[index] = childData;
      setAllEvents(allEventsCopy);
    }
  };

  return (
    <div className="container clearfix">
      <button
        className="btn btn-primary"
        onClick={onCreateClick}
        type="button"
      >
        Create new event
      </button>
      <Table
        columns={columns}
        data={allEvents}
        hiddenColumn={["id"]}
        rememberFilter={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Creation of event" : "Modification of event"}
      >
        <CreateEvent
          row={rowData}
          isCreate={isCreate}
          handleCallback={handleCallback}
        />
      </SideModal>
    </div>
  );
};

export default Events;
