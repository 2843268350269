import styled from "styled-components";

export const TableScrollContainer = styled.div`
  overflow-x: auto;
  padding-bottom: 8px;
`;

export const TableContainer = styled.div`

  width: 100%;
  table {
    width: 100%;
    border-collapse: unset;
    tr th {
      border-bottom: 2px solid #333;
      text-align: left;
      padding-left: 2px;
      padding-right: 2px;
      border-right: none;
      
    }
    tr td {
      border-bottom: 1px solid #e5e5e5;
      text-align: left;
      padding-left: 2px;
      padding-right: 2px;
      border-right: none;
    }
  }
  .pagination {
    padding: 0.5rem;
    width: 100%;
    justify-content: space-between;
  }
  .noResult {
    font-style: italic;
    text-align: center;
    display: block;
    height: 40px;
  }
  
  .selectedRow {
    background-color: #e8e8e9;
  }

  .pointerRow {
    cursor: pointer;
    
    &:hover {
      background-color: #e8e8e9;
    }
  }
`;

export const PaginateButton = styled.button`
  border: 1px solid #ddd;
  background-color: #fff;
  color: #0095f6;

  &:disabled {
    background-color: #efefef;
    color: #555;
    opacity: 0.5;
  }

  &:first-of-type {
    border-radius: 4px 0 0 4px;
    border-right: none;
  }

  &:last-of-type {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const PaginateSelect = styled.select`
  width: 150px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
`;
