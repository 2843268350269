import { createContext, useEffect, useState } from "react";
import ApplicationsClient from "../api-services/applications/applications-client";

const ApplicationContext  = createContext(true);

export const ApplicationProvider = ({ children }) => {
    const [applications, setApplications] = useState([]);

    const client = new ApplicationsClient();

    const getAllSupportedApplications = async () => {
        await client.getAllSupportedApplications().then((response) => {
            setApplications(response);
        });
    };

    useEffect(() => {
        getAllSupportedApplications()
    }, []);

    return(
        <ApplicationContext.Provider value={{applications, setApplications}}>
            {children}
        </ApplicationContext.Provider>
    )
};

export default ApplicationContext;