import AlertDialog from "../alert-dialog/AlertDialog.component";
import { useState } from "react";

const ButtonWithConfirmation = ({
  onClick,
  className,
  children,
  disabled,
  contentText,
  handleYesText,
  ...rest
}) => {
  const [showAlert, setShowAlert] = useState(false);

  const openDialog = () => setShowAlert(true);
  const hideDialog = () => setShowAlert(false);
  return (
    <>
      <button
        type="button"
        onClick={openDialog}
        className={className}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
      {showAlert && (
        <AlertDialog
          handleYes={() => {
            onClick();
            hideDialog();
          }}
          handleNo={hideDialog}
          show={showAlert}
          contentText={contentText}
          handleYesText={handleYesText}
          className={"btn btn-primary"}
        ></AlertDialog>
      )}
    </>
  );
};

export default ButtonWithConfirmation;
