import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import NotificationsClient from "../../api-services/notifications/notifications-client";

const SearchEvents = ({
  filterData,
  setFilterData,
  handleCallback,
  resetFilters,
}) => {
  const { tenantId } = useParams();

  const [targets, setTargets] = useState([]);
  const options = [
    {
      label: "Active",
      value: true,
    },
    {
      label: "Inactive",
      value: false,
    },
  ];

  const client = new NotificationsClient();

  useEffect(() => {
    getTargetInfos();
  }, []);

  const getTargetInfos = async () => {
    let response = await client.getTargetInfos(tenantId, true);
    let data = response.map(x => {
      return { value: x.id, label: x.name };
    });
    setTargets(data);
  }

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4">
          <label>Event name</label>
          <input
            type="text"
            className="form-control"
            value={filterData.eventName}
            onChange={(e) =>
              setFilterData({ ...filterData, eventName: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>Target name</label>
          <Select
            placeholder={"Choose option"}
            options={targets}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                notificationTargetInfoName: e?.label,
              });
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            value={targets.filter(
              (x) => x.label == filterData.notificationTargetInfoName
            )}
            isClearable
          />
        </div>
        <div className="form-group col-md-4">
          <label>At least one target per notification</label>
          <Select
            placeholder={"Choose option"}
            options={options}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                eventChannelBehaviourTargetStatus: e?.value,
              });
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            value={options.filter(
              (x) => x.value == filterData.eventChannelBehaviourTargetStatus
            )}
            isClearable
          />
        </div>
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleCallback();
            }}
          >
            Search
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={resetFilters}
          >
            Reset filters
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchEvents;
