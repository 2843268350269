class TenantModel {
    constructor(
        id = 0,
        tenantName = "",
    ) {
        this.id = id;
        this.tenantName = tenantName;
    }
}

export { TenantModel };
