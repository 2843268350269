import React from "react";
import { Navigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../common/loader/Loader.component";

const Settings = () => {
  const { tenantId } = useParams();
  return (
    <>
      <LoadingSpinner />
      <Navigate to={"/Settings/Channels" + "/" + tenantId} />
    </>
  );
};

export default Settings;
