class EventModel {
    constructor(
        id = 0,
        name = "",
        subjectTemplate = "",
        bodyTemplate = "",
        ruleEventId = 0,
        ruleName = "",
        ruleId = 0,
        notificationSpaceId = 0,
        status = false,
        entityTypeId = ""
    ) {
        this.id = id;
        this.name = name;
        this.subjectTemplate = subjectTemplate;
        this.bodyTemplate = bodyTemplate;
        this.ruleEventId = ruleEventId;
        this.ruleName = ruleName;
        this.ruleId = ruleId;
        this.notificationSpaceId = notificationSpaceId;
        this.status = status;
        this.entityTypeId = entityTypeId;
    }
}

export { EventModel };
