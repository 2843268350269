import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export const NSpaceNav = [
    {
      title: "Rules",
      path: "/Rules",
      icon: <FontAwesomeIcon icon={solid("list-ul")} />,
      cName: "nav-text"
    },
    {
      title: "Events",
      path: "/Events",
      icon: <FontAwesomeIcon icon={solid("calendar-check")} />,
      cName: "nav-text"
    },
    {
      title: "Notifications",
      path: "/Notifications",
      icon: <FontAwesomeIcon icon={solid("bell")} />,
      cName: "nav-text"
    },
  ];
  
  export const SettingsNav = [
    {
      title: "Channels",
      pathForNav: "Channels",
      path: "/Settings/Channels",
      icon: <FontAwesomeIcon icon={solid("sitemap")} />,
      cName: "nav-text"
    },
    {
      title: "Notification Target Info",
      pathForNav:"TargetInfo",
      path: "/Settings/TargetInfo",
      icon: <FontAwesomeIcon icon={solid("dot-circle")} />,
      cName: "nav-text"
    }
  ];

  export const GeneralSettingsNav = [
    {
      title: "Tenants",
      pathForNav: "Tenants",
      path: "/GeneralSettings/Tenants",
      icon: <FontAwesomeIcon icon={solid("industry")} />,
      cName: "nav-text",
    },
    {
      title: "Applications",
      pathForNav: "Applications",
      icon: <FontAwesomeIcon icon={solid("bars")} />,
      path: "/GeneralSettings/Applications",
      cName: "nav-text",
    },
    {
      title: "Rules types",
      pathForNav: "RulesTypes",
      icon: <FontAwesomeIcon icon={solid("pen-ruler")} />,
      path: "/GeneralSettings/RulesTypes",
      cName: "nav-text",
    },
    {
      title: "Tokens",
      pathForNav: "Tokens",
      icon: <FontAwesomeIcon icon={solid("coins")} />,
      path: "/GeneralSettings/Tokens",
      cName: "nav-text",
    }
  ];

 