import { useEffect, useState } from "react";
import { EmailAddress } from "../../../models/NotificationTargetInfo";

const CreateEmailGroupTargerInfo = ({ handleArrayChange, mailAddresses }) => {
  const [mailAddress, setMailAddress] = useState([new EmailAddress()]);

  useEffect(() => {
    if (mailAddresses?.length > 0) {
      setMailAddress(mailAddresses);
    }
  }, []);

  useEffect(() => {
    handleArrayChange("mailAddresses", mailAddress);
  }, [mailAddress]);

  const handleChange = (value) => {
    let mailAddressCopy = [...mailAddress];
    mailAddressCopy[0].email = value;
    setMailAddress(mailAddressCopy);
  }

  return (
    <div className="form-group row g-2">
      <div className="col-md">
        <label>Email of group</label>
        <input className="form-control"
          type="text"
          name="groupName"
          onChange={(e) => handleChange(e.target.value)}
          value={mailAddress[0].email}
        ></input>
      </div>
    </div>
  );
};

export default CreateEmailGroupTargerInfo;
