import { useState, useEffect } from "react";
import { ApplicationModel } from "../../models/Application";
import ApplicationsClient from "../../api-services/applications/applications-client";

const CreateApplication = ({ row, isCreate, handleCallback }) => {
  const [application, setApplication] = useState(new ApplicationModel());
  const [isCreateApplication, setIsCreateApplication] = useState(true);

  const client = new ApplicationsClient();

  useEffect(() => {
    setApplication(row);
    setIsCreateApplication(isCreate)
  }, [row, isCreate]);

  const save = async () => {
    if (isCreateApplication) {
      var response = await client.createSupportedApplication(application);
    } else {
      var response = await client.updateSupportedApplication(application);
    }
    handleCallback(response);
  };

  return (
    <form>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Name</label>
          <input
            className="form-control"
            value={application.name}
            onChange={(event) => setApplication({ ...application, name: event.target.value })}
          />
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          type="button"
          onClick={save}
        >
          {isCreateApplication ? "Create" : "Save changes"}
        </button>
      </div>
    </form>
  )
};

export default CreateApplication;