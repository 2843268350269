import { Outlet } from "react-router-dom";
import Navbar from "../navigation/Navigation.component";
import LoadingSpinner from "../../common/loader/Loader.component";
import { useState, useEffect } from "react";
import useNav from "../../hooks/useNav";
import { MainContainer } from "./Layout.style";
import API from "../../api";

const Layout = () => {
  const { sidebar, setSidebar } = useNav(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    API.interceptors.request.use(
      (config) => {
        if (!config.isBackground) {
          setLoading(true);
        }
        return config;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    API.interceptors.response.use(
      function (response) {
        setLoading(false);

        return response;
      },
      async function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      <MainContainer className={sidebar ? "App" : "collapsed App"}>
        {loading ? <LoadingSpinner /> : <></>}
        <Navbar />
        <Outlet />
      </MainContainer>
    </>
  );
};

export default Layout;