import { createContext, useEffect, useState } from "react";

const NotificationSpaceContext = createContext(true);

export const NotificationSpaceProvider = ({ children }) => {
  const [notificationSpace, setNotificationSpace] = useState("");

  useEffect(() => {
    let notificationSpaceState = localStorage.getItem("notificationSpace");
    setNotificationSpace(notificationSpaceState);
  }, []);

  useEffect(() => {
    if (notificationSpace) {
      localStorage.setItem("notificationSpace", notificationSpace);
    }
  }, [notificationSpace]);

  return (
    <NotificationSpaceContext.Provider value={{ notificationSpace, setNotificationSpace }}>
      {children}
    </NotificationSpaceContext.Provider>
  );
};

export default NotificationSpaceContext;
