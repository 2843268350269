import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { RuleModel } from "../../models/Rule";
import CreateRule from "../../components/create-rule/CreateRule.component";
import SideModal from "../../common/modal/SideModal.component";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import Table from "../../common/table/Table.component";
import { SelectColumnFilter } from "../../common/table/filterHelpFunction.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import RulesClient from "../../api-services/rules/rules-client";

const Rules = () => {
  const { tenantId } = useParams();
  const { notificationSpaceId } = useParams();

  const [rules, setRules] = useState([]);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState(new RuleModel());
  const [entityTypeName, setEntityTypeName] = useState("");
  const [isCreate, setIsCreate] = useState(true);

  const filter = {
    filterValue: "equals",
    selectOptions: [
      { value: undefined, label: "All" },
      { value: "false", label: "Current" },
      { value: "true", label: "Scheduled" },
    ],
  };

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Rule name",
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: "Rule type",
      accessor: "entityType.name",
      disableFilters: false,
    },
    {
      Header: "Entity type",
      accessor: "entityType.id",
      disableFilters: false,
    },
    {
      Header: "Rule template",
      accessor: "ruleTemplate",
      disableFilters: false,
    },
    {
      Header: "Status",
      accessor: "ruleForScheduleNotification",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return row.original.ruleForScheduleNotification
          ? "Scheduled"
          : "Current";
      },
    },
    {
      accessor: "edit",
      Cell: ({ row }) => {
        return (
          <button
            className="btn btn-primary table-btn"
            onClick={() => onEditClick(row.original)}
          >
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </button>
        );
      },
      disableFilters: true,
    },
    {
      accessor: "delete",
      Cell: ({ row }) => {
        return (
          <ButtonWithConfirmation
            className="btn btn-danger"
            onClick={() => onDeleteClick(row.original.id)}
            handleYesText={"Delete"}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </ButtonWithConfirmation>
        );
      },
      disableFilters: true,
    },
  ];

  const client = new RulesClient();

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  useEffect(() => {
    if (tenantId && notificationSpaceId) {
      getAllRules();
    }
  }, [tenantId, notificationSpaceId]);

  const getAllRules = async () => {
    let response = await client.getAllRules(tenantId, notificationSpaceId);
    setRules(response);
  };

  const handleCallback = (childData, entityName) => {
    hideModal();
    childData.entityType = { id: childData.entityTypeId, name: entityName };
    if (isCreate) setRules([...rules, childData]);
    else {
      let allRulesCopy = [...rules];
      let index = allRulesCopy.findIndex((x) => x.id === childData.id);
      allRulesCopy[index] = childData;
      setRules(allRulesCopy);
    }
  };

  const onCreateClick = () => {
    setRowData(new RuleModel());
    setEntityTypeName("");
    setIsCreate(true);
    showModal();
  };

  const onEditClick = (row) => {
    if (row.name == null) row.name = "";
    setRowData(row);
    setEntityTypeName(row.entityType.name);
    setIsCreate(false);
    showModal();
  };

  const onDeleteClick = async (ruleId) => {
    await client.removeRule(tenantId, ruleId);
    let rulesCopy = rules.filter((x) => x.id !== ruleId);
    setRules(rulesCopy);
  };

  return notificationSpaceId ? (
    <div className="container clearfix">
      <button
        className="btn btn-primary"
        onClick={onCreateClick}
        type="button"
      >
        Create new rule
      </button>
      <Table
        columns={columns}
        data={rules}
        hiddenColumn={["entityType.id", "id"]}
        rememberFilter={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isCreate ? "Creation of rule" : "Modification of rule"}
      >
        <CreateRule
          row={rowData}
          entityTypeName={entityTypeName}
          isCreate={isCreate}
          handleCallback={handleCallback}
        />
      </SideModal>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Rules;
