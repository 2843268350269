import { useState, useEffect } from "react";
import Select from "react-select";
import useApplications from "../../hooks/useApplications";
import { RuleTypeModel } from "../../models/RuleType";
import RulesTypesClient from "../../api-services/rules-types/rules-types-client";

const CreateRuleType = ({ row, isCreate, handleCallback }) => {
  const { applications, setApplications } = useApplications([]);

  const [ruleType, setRuleType] = useState(new RuleTypeModel());
  const [isCreateRuleType, setIsCreateRuleType] = useState(true);
  const mappedApplications = applications.map((x) => {
    return { value: x.id, label: x.name }
  });

  const client = new RulesTypesClient();

  useEffect(() => {
    setRuleType(row);
    setIsCreateRuleType(isCreate)
  }, [row, isCreate]);

  const save = async () => {
    if (isCreateRuleType) {
      var response = await client.createEntityType(ruleType);
    } else {
      var response = await client.updateEntityType(ruleType);
    }
    handleCallback(response);
  };

  return (
    <form>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Name</label>
          <input
            className="form-control"
            value={ruleType.name}
            onChange={(event) => setRuleType({ ...ruleType, name: event.target.value })}
          />
        </div>
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Key</label>
          <input
            className="form-control"
            value={ruleType.entityTypeKey}
            onChange={(event) => setRuleType({ ...ruleType, entityTypeKey: event.target.value })}
          />
        </div>
      </div>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Application</label>
          <Select
            options={mappedApplications}
            onChange={(event) => setRuleType({ ...ruleType, supportedApplicationId: event.value })
            }
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={mappedApplications.filter((x) => x.value === ruleType.supportedApplicationId)}
          />
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          type="button"
          onClick={save}
        >
          {isCreateRuleType ? "Create" : "Save changes"}
        </button>
      </div>
    </form>
  )
};

export default CreateRuleType;