import styled from "styled-components";

export const SwitchContainer = styled.div`

  position: relative;
  
  label{
    display: flex;
    align-items: center;
    font-family: sans-serif;
    cursor: pointer;
  }
  
  .indicator{
    position: relative;
    width: 60px;
    height: 30px;
    background: #ffffff;
    border: 2px solid #efefef;
    display: block;
    border-radius: 30px;
    margin-left: 10px;
    box-shadow: 0 0 2px rgba(0,0,0,.25);
    
    &:before{
      width: 22px;
      height: 22px;
      content: "";
      display: block;
      background: #efefef;
      border-radius: 26px;
      transform: translate(2px, 2px);
      position: relative;
      z-index: 2;
      transition: all .5s;
    }
    &:after{
      width: 0;
      content: "";
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      height: 22px;
      background: #efefef;
      border-radius: 26px;
      z-index: 1;
      transition: all .5s;
    }
  }
  input{
    visibility: hidden;
    position: absolute;
    
    &:checked{
      &+.indicator{
        &:before{
          transform: translate(32px, 2px);
          background: #25c138;
        }
        &:after{
          width: 54px;
        }
      }
    }
  }
`