import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { removeElementAt } from "../../../utils/array-methods/array-methods.js";
import { NewUserInfoDiv } from "./CreateUserTargetInfo.style.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Select from "react-select";
import {
  EmailAddress,
  PhoneNumber,
  NotificationSpaceUserGroupIdentifier,
} from "../../../models/NotificationTargetInfo.js";
import IndexClient from "../../../api-services/index/index-client.js";

const CreateUserTargerInfo = React.memo(
  ({
    handleInputChange,
    handleArrayChange,
    firstName,
    lastName,
    mailAddresses,
    phoneNumbers,
    notificationSpaceUserGroupIdentifiers,
  }) => {
    const { tenantId } = useParams();

    const [emailsArray, setEmailsArray] = useState(mailAddresses);
    const [phonesArray, setPhonesArray] = useState(phoneNumbers);
    const [notificationSpaceUserGroupIdentifiersArray, setNotificationSpaceUserGroupIdentifiersArray] = useState(notificationSpaceUserGroupIdentifiers);
    const [notificationSpaces, setNotificationSpaces] = useState([]); //ovo za select listu

    const client = new IndexClient();

    useEffect(() => {
      if (tenantId) {
        getNotificationSpaces();
      }
    }, []);

    useEffect(() => {
      handleArrayChange("mailAddresses", emailsArray);
    }, [emailsArray]);

    useEffect(() => {
      handleArrayChange("phoneNumbers", phonesArray);
    }, [phonesArray]);

    useEffect(() => {
      handleArrayChange(
        "notificationSpaceUserGroupIdentifiers",
        notificationSpaceUserGroupIdentifiersArray
      );
    }, [notificationSpaceUserGroupIdentifiersArray]);

    useEffect(() => {
      setEmailsArray(mailAddresses);
    }, [mailAddresses]);

    useEffect(() => {
      setPhonesArray(phoneNumbers);
    }, [phoneNumbers]);

    useEffect(() => {
      setNotificationSpaceUserGroupIdentifiersArray(
        notificationSpaceUserGroupIdentifiers
      );
    }, [notificationSpaceUserGroupIdentifiers]);

    const getNotificationSpaces = async () => {
      await client.GetAllNotificationSpaces(tenantId).then((response) => {
        let data = response.map((item) => {
          return { value: item.id, label: item.name };
        });
        setNotificationSpaces(data);
      });
    };

    const insertNewEmail = (value, index) => {
      let emailsArrayCopy = [...emailsArray];
      emailsArrayCopy[index].email = value;
      setEmailsArray(emailsArrayCopy);
    };

    const insertNewPhoneNumber = (value, index) => {
      let phonesArrayCopy = [...phonesArray];
      phonesArrayCopy[index].phoneNr = value;
      setPhonesArray(phonesArrayCopy);
    };

    const insertNewNotificationSpaceUserGroupIdentifiers = (index, extUserId) => {
      let notificationSpaceUserGroupIdentifiersArrayCopy = [...notificationSpaceUserGroupIdentifiersArray];
      notificationSpaceUserGroupIdentifiersArrayCopy[index].externalUserIdentifier = extUserId;
      setNotificationSpaceUserGroupIdentifiersArray(notificationSpaceUserGroupIdentifiersArrayCopy);
    };

    const insertNewNotificationSpace = (index, notSpaceId) => {
      let notificationSpaceUserGroupIdentifiersArrayCopy = [...notificationSpaceUserGroupIdentifiersArray];
      notificationSpaceUserGroupIdentifiersArrayCopy[index].notificationSpaceId = notSpaceId;
      setNotificationSpaceUserGroupIdentifiersArray(notificationSpaceUserGroupIdentifiersArrayCopy);
    }

    return (
      <>
        <div className="form-group row g-2">
          <div className="col-md">
            <label>User's first name</label>
            <input
              className="form-control"
              type="text"
              name="firstName"
              onChange={handleInputChange}
              value={firstName}
            />
          </div>
          <div className="col-md">
            <label>User's last name</label>
            <input
              className="form-control"
              type="text"
              name="lastName"
              onChange={handleInputChange}
              value={lastName}
            />
          </div>
        </div>
        <div className="form-group row g-2">
          <div className="col-md">
            <label htmlFor="NotificationSpaces">Notification space</label>
          </div>
          <div className="col-md">
            <label htmlFor="NotificationSpaces">User's external identifier</label>
          </div>
        </div>
        {notificationSpaceUserGroupIdentifiers.map((x, index) => {
          return (
            <div className="form-group row g-2">
              <div className="col-md">
                <Select
                  id="NotificationSpaces"
                  options={notificationSpaces}
                  onChange={(event) => {
                    insertNewNotificationSpace(index, event.value);
                  }}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  value={notificationSpaces.find((y) => y.value === x.notificationSpaceId)}
                />
              </div>
              <div className="col-md">
                <NewUserInfoDiv key={index}>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      insertNewNotificationSpaceUserGroupIdentifiers(index, e.target.value)
                    }
                    }
                    value={x.externalUserIdentifier}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setNotificationSpaceUserGroupIdentifiersArray(removeElementAt(notificationSpaceUserGroupIdentifiersArray, index));
                    }}
                  >
                    <FontAwesomeIcon icon={solid("times")} />
                  </button>
                </NewUserInfoDiv>
              </div>
            </div>
          );
        })}
        <div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              if (notificationSpaceUserGroupIdentifiersArray.length > 0)
                setNotificationSpaceUserGroupIdentifiersArray([...notificationSpaceUserGroupIdentifiersArray, new NotificationSpaceUserGroupIdentifier()])
              else
                setNotificationSpaceUserGroupIdentifiersArray([new NotificationSpaceUserGroupIdentifier()])
            }
            }
          >
            + Add new external identifier
          </button>
        </div>
        <br />
        <div className="form-group row g-2">
          <div className="col-md">
            <label>User's email</label>
            {emailsArray.map((x, index) => {
              return (
                <NewUserInfoDiv key={index}>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => insertNewEmail(e.target.value, index)}
                    value={x.email}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setEmailsArray(removeElementAt(emailsArray, index))
                    }
                  >
                    <FontAwesomeIcon icon={solid("times")} />
                  </button>
                </NewUserInfoDiv>
              );
            })}
            <div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  if (emailsArray.length > 0) {
                    setEmailsArray([...emailsArray, new EmailAddress()])
                  }
                  else {
                    setEmailsArray([new EmailAddress()])
                  }
                }
                }
              >
                + Add new email
              </button>
            </div>
          </div>

          <div className="col-md">
            <label>User's phone number</label>
            {phonesArray.map((x, index) => {
              return (
                <NewUserInfoDiv key={index}>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) =>
                      insertNewPhoneNumber(e.target.value, index)
                    }
                    value={x.phoneNr}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setPhonesArray(removeElementAt(phonesArray, index))
                    }
                  >
                    <FontAwesomeIcon icon={solid("times")} />
                  </button>
                </NewUserInfoDiv>
              );
            })}
            <div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  if (phonesArray.length > 0)
                    setPhonesArray([...phonesArray, new PhoneNumber()])
                  else
                    setPhonesArray([new PhoneNumber()])
                }
                }
              >
                + Add new phone number
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default CreateUserTargerInfo;
