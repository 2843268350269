import API from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class IndexClient {
  async GetPrivilege(internalIdentityId) {
    var response = await API.get(
      "/GetUserTenantPrivilegeByInternalIdentityId",
      {
        headers: { internalIdentityId: internalIdentityId },
      }
    );
    return response.data;
  }

  async GetAllTenants() {
    var response = await API.get("/getAllTenants");
    return response.data;
  }

  async GetAllNotificationSpaces(tenantId) {
    var response = await API.get("/getAllNotificationSpaces", {
      headers: { tenantId: tenantId },
    });
    return response.data;
  }

  async GetAllSupportedApplications() {
    var response = await API.get("/getAllSupportedApplications");
    var data = response.data.map((item) => {
      return { value: item.id, label: item.name };
    });
    return data;
  }

  async CreateNotificationSpace(tenantId, data) {
    const headers = {
      "Content-Type": "application/json",
      tenantId: tenantId,
    };
    try {
      var response = await API.post("/createNotificationSpace", data, {
        headers: headers,
      });
      if (response.status !== 200) {
        Error(response.message);
      } else {
        Success("Successfully!");
        return response.data;
      }
    } catch (err) {
      Error(err.message);
    }
  }

  async UpdateNotificationSpace(tenantId, data) {
    const headers = {
      "Content-Type": "application/json",
      tenantId: tenantId,
    };
    try {
      var response = await API.post("/updateNotificationSpace", data, {
        headers: headers,
      });
      if (response.status !== 200) Error(response.message);
      else {
        Success("Successfully!");
        return response.data;
      }
    } catch (err) {
      Error(err.message);
    }
  }
}

export default IndexClient;
