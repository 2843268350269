import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Channel, ChannelTypes } from "../../models/Channel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import JSONPretty from "react-json-pretty";
import Table from "../../common/table/Table.component";
import SideModal from "../../common/modal/SideModal.component";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import CreateNewChannel from "../../components/create-channel/CreateChannel.component";
import ChannelsClient from "../../api-services/channels/channels-client";

const Channels = () => {
  const { tenantId } = useParams();

  const [rowData, setRowData] = useState(new Channel());
  const [allChannels, setAllChannels] = useState([]);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Channels",
        accessor: "channelTypeLabel",
        disableFilters: false,
      },
      {
        Header: "Name",
        accessor: "name",
        disableFilters: false,
      },
      {
        Header: "Conf JSON",
        accessor: "configuration",
        disableFilters: false,
        Cell: ({ row }) => {
          return <JSONPretty data={row.original.configuration}></JSONPretty>;
        },
      },
      {
        accessor: "edit",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary table-btn"
              onClick={() => onEditClick(row.original)}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </button>
          );
        },
        disableFilters: true,
      },
      {
        accessor: "delete",
        Cell: ({ row }) => {
          return (
            <ButtonWithConfirmation
              className="btn btn-danger"
              onClick={() => onDeleteClick(row.original.id)}
              handleYesText={"Delete"}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithConfirmation>
          );
        },
        disableFilters: true,
      },
    ],
    []
  );

  var client = new ChannelsClient();

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  useEffect(() => {
    if (tenantId) {
      getChannelsList();
    }
  }, []);

  const getChannelsList = async () => {
    let response = await client.getAllChannels(tenantId);
    if (response) {
      response.map((x) => {
        ChannelTypes.map((y) => {
          if (y.value === x.channelType) {
            x.channelType = y.value;
            x.channelTypeLabel = y.label;
          }
        });
      });
      setAllChannels(response);
    }
  };

  const onCreateClick = () => {
    setIsEdit(false);
    setRowData(new Channel());
    showModal();
  };

  const onEditClick = (row) => {
    setIsEdit(true);
    setRowData(row);
    showModal();
  };

  const onDeleteClick = async (channelId) => {
    await client.removeChannel(tenantId, channelId);
    let allChannelsCopy = allChannels.filter((x) => x.id !== channelId);
    setAllChannels(allChannelsCopy);
  };

  const handleCallback = (childData) => {
    ChannelTypes.map((y) => {
      if (y.value === childData.channelType) {
        childData.channelTypeLabel = y.label;
      }
    });
    hideModal();
    if (!isEdit) setAllChannels([...allChannels, childData]);
    else {
      let allChannelsCopy = [...allChannels];
      let index = allChannelsCopy.findIndex((x) => x.id === childData.id);
      allChannelsCopy[index] = childData;
      setAllChannels(allChannelsCopy);
    }
  };

  return tenantId ? (
    <div className="container clearfix">
      <h4 className="text-center">{"Settings > Channels"}</h4>
      <button className="btn btn-primary" onClick={onCreateClick}>
        Create new channel
      </button>
      <Table
        columns={columns}
        data={allChannels}
        hiddenColumn={["id"]}
        rememberFilter={false}
      />
      <SideModal
        show={show}
        handleClose={hideModal}
        title={isEdit ? "Modification of channel" : "Creation of channel"}
      >
        <CreateNewChannel
          row={rowData}
          isEdit={isEdit}
          handleCallback={handleCallback}
        />
      </SideModal>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Channels;
