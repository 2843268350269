import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Error } from "../../common/toastify/toastify";
import { TargetInfoTypes } from "../../models/NotificationTargetInfo";
import Select from "react-select";
import { SwitchContainer } from "../../common/switch/Switch.style";
import NotificationsClient from "../../api-services/notifications/notifications-client";

const CreateTarget = ({ channel, event, handleCreate }) => {
    const { tenantId } = useParams();

    const [targetInfos, setTargetInfos] = useState([]);
    const [types, setTypes] = useState([]);
    const [notificationType, setNotificationType] = useState();
    const [behaviourType, setBehaviourType] = useState();
    const [targetType, setTargetType] = useState();
    const [targetEmails, setTargetEmails] = useState([]);
    const [chosenEmails, setChosenEmails] = useState([]);
    const [targetPhoneNumbers, setTargetPhoneNumbers] = useState([]);
    const [chosenPhoneNumbers, setChosenPhoneNumbers] = useState([]);
    const [status, setStatus] = useState(false);

    const nClient = new NotificationsClient();

    useEffect(() => {
        getTargetInfo();
    }, []);

    useEffect(() => {
        if (targetType) {
            getTargetContacts();
        }
    }, [targetType]);

    const getTargetInfo = async () => {
        let response = await nClient.getNotificationTargetInfos(tenantId, true);
        let targetTypes = response.map((item) => {
            var target = {
                value: item.id,
                label:
                    item.notificationTargetType == 1
                        ? `${item.name} (${item.firstName} ${item.lastName})`
                        : item.notificationTargetType == 2
                            ? `${item.name} (${item.groupName})`
                            : item.name,
                notificationTargetType: item.notificationTargetType,
            };
            if (item.notificationTargetType == 2)
                target.mailId = item.mailAddresses.map((x) => x.id);
            return target;
        });
        setTargetInfos(response);
        setTypes(targetTypes);
    };

    const getTargetContacts = () => {
        var target = targetInfos.filter((item) => item.id === targetType)[0];
        if (target.notificationTargetType === 1) {
            var emails = target.mailAddresses.map((contact) => {
                return { value: contact.id, label: contact.email };
            });
            var numbers = target.phoneNumbers.map((contact) => {
                return { value: contact.id, label: contact.phoneNr };
            });
            setTargetEmails(emails);
            setTargetPhoneNumbers(numbers);
        } else {
            setTargetEmails([]);
            setTargetPhoneNumbers([]);
        }
        setNotificationType(target.notificationTargetType);
        setBehaviourType(notificationType === 2 ? 2 : 1);
    };

    const createNotification = async () => {
        var mailAdresses = [];

        if (notificationType === 2) {
            mailAdresses = types.filter((x) => x.value === targetType)[0].mailId;
        } else {
            mailAdresses = chosenEmails.map((item) => item.value);
        }

        const data = {
            eventId: event,
            channelId: channel.id,
            behaviourType: behaviourType,
            notificationTargetInfoId: targetType,
            status: status,
            mailAddressIds: mailAdresses,
            phoneNumberIds: chosenPhoneNumbers.map((item) => {
                return item.value;
            }),
        };
        if (
            notificationType === 1 &&
            data.mailAddressIds.length === 0 &&
            data.phoneNumberIds.length === 0
        ) {
            Error(
                "Select the user's e-mail or phone number to create the notification."
            );
            return;
        } else {
            await nClient.createNotification(tenantId, data);
            resetForm();
            handleCreate();
        }
    };

    const resetForm = () => {
        setNotificationType();
        setTargetType();
        setTargetEmails([]);
        setChosenEmails([]);
        setTargetPhoneNumbers([]);
        setChosenPhoneNumbers([]);
    };

    const filterChannels = (channelType) => {
        if (channelType === 1) {
            return types.filter(
                (x) =>
                    x.notificationTargetType === TargetInfoTypes.User.id ||
                    x.notificationTargetType === TargetInfoTypes.EmailGroup.id
            );
        } else if (channelType === 2) {
            return types.filter(
                (x) => x.notificationTargetType === TargetInfoTypes.User.id
            );
        } else if (channelType === 3) {
            return types.filter(
                (x) => x.notificationTargetType === TargetInfoTypes.ViberChannel.id
            );
        } else if (channelType === 4) {
            return types.filter(
                (x) => x.notificationTargetType === TargetInfoTypes.TeamsChannel.id
            );
        } else if (channelType === 5) {
            return types.filter(
                (x) => x.notificationTargetType === TargetInfoTypes.User.id
            );
        }
        return types;
    };

    return (
        <div>
            <div className="form-group">
                <SwitchContainer>
                    <label htmlFor="switch">
                        <span className="label">Active</span>
                        <input
                            id="switch"
                            type="checkbox"
                            checked={status}
                            value={status}
                            onChange={(event) =>
                                setStatus(event.target.checked)
                            }
                        />
                        <span className="indicator"></span>
                    </label>
                </SwitchContainer>
            </div>
            <div className="form-group">
                <label>Target</label>
                <Select
                    options={filterChannels(channel?.type)}
                    onChange={(event) => {
                        setTargetType(event.value);
                        setChosenEmails([]);
                        setChosenPhoneNumbers([]);
                    }}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={types.filter((x) => x.value === targetType)}
                />
            </div>
            {targetEmails.length > 0 && (
                <div className="form-group">
                    <label>E-mail</label>
                    <Select
                        options={targetEmails}
                        onChange={(event) => {
                            setChosenEmails(event);
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        value={chosenEmails}
                        isMulti
                    />
                </div>
            )}
            {targetPhoneNumbers.length > 0 && (
                <div className="form-group">
                    <label>Phone number</label>
                    <Select
                        options={targetPhoneNumbers}
                        onChange={(event) => {
                            setChosenPhoneNumbers(event);
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        value={chosenPhoneNumbers}
                        isMulti
                    />
                </div>
            )}
            {notificationType === 2 && (
                <div>
                    <span>Individual sending&nbsp;</span>
                    <input
                        type="checkbox"
                        className="checkbox-position"
                        onChange={(event) => {
                            event.target.checked
                                ? setBehaviourType(1)
                                : setBehaviourType(2);
                        }}
                    />
                </div>
            )}
            <button
                className="btn btn-primary"
                style={{ marginTop: "20px" }}
                type="button"
                onClick={createNotification}
            >
                Create
            </button>
        </div>
    )
}

export default CreateTarget;