import { useState, useEffect } from "react";
import { TenantModel } from "../../models/Tenant";
import TenantsClient from "../../api-services/tenants/tenants-client";

const CreateTenant = ({ row, isCreate, handleCallback }) => {
  const [tenant, setTenant] = useState(new TenantModel());
  const [isCreateTenant, setIsCreateTenant] = useState(true);

  const client = new TenantsClient();

  useEffect(() => {
    setTenant(row);
    setIsCreateTenant(isCreate);
  }, [row, isCreate]);

  const save = async () => {
    if (isCreateTenant) {
      var response = await client.createTenant(tenant.tenantName);
    } else {
      var response = await client.updateTenant(tenant);
    }
    handleCallback(response);
  };

  return (
    <form>
      <div className="form-group row g-2">
        <div className="col-md">
          <label>Name</label>
          <input
            className="form-control"
            value={tenant.tenantName}
            onChange={(event) => setTenant({ ...tenant, tenantName: event.target.value })}
          />
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary m-2"
          type="button"
          onClick={save}
        >
          {isCreateTenant ? "Create" : "Save changes"}
        </button>
      </div>
    </form>
  );
};

export default CreateTenant;
