import { useParams, useNavigate } from "react-router-dom";
import { NotificationSpaceContainer } from "./NotificationSpace.style";
import useUserPrivileges from "../../hooks/useUserPrivileges";

const NotificationSpace = (props) => {
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const { userPrivileges, setUserPrivileges } = useUserPrivileges();

  return (
    <NotificationSpaceContainer>
      <div
        onClick={() => {
          let pathname = userPrivileges ? "/Rules" : "/Notifications";
          navigate(pathname + "/" + tenantId + "&" + props.id)
        }}
      >
        <div
          className="notification-space-container"
        >
          <div className="n-s-title">{props.name}</div>
          <div className="n-s-key">
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {props.children}
            </span>
          </div>
        </div>
      </div>
    </NotificationSpaceContainer>
  );
}

export default NotificationSpace;