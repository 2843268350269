import api from "../../api";
import { Success, Error } from "../../common/toastify/toastify";

class TenantsClient {

    async getAllTenants() {
        var response = await api.get("/getAllTenants");
        return response.data;
    }

    async createTenant(tenantName) {
        try {
            var response = await api.post("/registerNewTenant?tenantName=" + tenantName);
            if (response.status !== 200)
                Error(response.message);
            else {
                Success("Successfully!");
                return response.data;
            }
        }
        catch (err) {
            Error(err?.message);
            throw err?.message
        }
    }

    async updateTenant(data) {
        try {
            var response = await api.post("/updateTenantByTenantId", data);
            if (response.status !== 200)
                Error(response.message);
            else
                Success("Successfully!");
            return response.data;
        }
        catch (err) {
            Error(err.message);
        }
    }
}

export default TenantsClient;