import { createContext, useEffect, useState } from "react";
import TokensClient from "../api-services/tokens/tokens-client";

const TokenContext = createContext(true);

export const TokenProvider = ({ children }) => {
    const [tokens, setTokens] = useState([]);

    const client = new TokensClient();

    const getAllTokenInfos = async () => {
        await client.getAllTokenInfos().then((response) => {
            setTokens(response);
        });
    };

    useEffect(() => {
        getAllTokenInfos()
    }, []);

    return(
        <TokenContext.Provider value={{tokens, setTokens}}>
            {children}
        </TokenContext.Provider>
    )
};

export default TokenContext;